<template>
    <div style="position:fixed; top:0;z-index:1;">
        <div style="" class="nav flex-column nav-pills nav-left-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <a :class="{'nav-link': true,'active' : (activeTab == 'vm_list' && perms.includes('prox-vm-list')), 'disabled': !perms.includes('prox-vm-list')}" href="/" role="tab" aria-selected="false">Servers</a>
    <a @click.prevent="show_subs" role="tab" :class="{'nav-link': true}"  aria-selected="false" style="color:white; cursor:pointer;">Firewall</a>
    <a href="/iplists" v-if="subs" role="tab" :class="{'nav-link': true,'active' : activeTab == 'iplists', 'disabled': !((perms.includes('prox-fw-add-ip') || perms.includes('prox-fw-del-ip')))}"   aria-selected="false" style="margin-left:5px;"> IpSets</a>
<a href="/serversets" v-if="subs" role="tab" :class="{'nav-link': true,'active' : activeTab == 'serversets', 'disabled': !((perms.includes('prox-fw-ipset-add-to-node')))}"   aria-selected="false" style="margin-left:5px;"> Server IpSets</a>
<a href="/whitelistvm" v-if="subs" role="tab" :class="{'nav-link': true,'active' : activeTab == 'whitelistvm', 'disabled': !((perms.includes('prox-fw-add-ip') || perms.includes('prox-fw-del-ip')))}"   aria-selected="false" style="margin-left:5px;"> Whitelist VM</a>
  <a :class="{'nav-link': true,'active' : activeTab == 'wire', 'disabled': !perms.includes('wire-get-ip')}"  href="/wire" role="tab"  aria-selected="false">Wire</a>
<a :class="{'nav-link': true,'active' : activeTab == 'cerberus', 'disabled': !perms.includes('cerberus-get-domain')}"   href="/cerberus" role="tab"  aria-selected="false">Cerberus</a>
<a :class="{'nav-link': true,'active' : activeTab == 'vpn', 'disabled': !perms.includes('vpn-get-server')}"   href="/vpn" role="tab"  aria-selected="false">VPN</a>
<a :class="{'nav-link': true,'active' : activeTab == 'ssl', 'disabled': !perms.includes('ssl-add-cert')}"   href="/ssl" role="tab"  aria-selected="false">SSL</a>
<a :class="{'nav-link': true,'active' : activeTab == 'nextcloud', 'disabled': !perms.includes('nextcloud-get-users')}"   href="/nextcloud" role="tab"  aria-selected="false">Nextcloud</a>
<a v-if="user=='spiros'" :class="{'nav-link': true,'active' : activeTab == 'jh', 'disabled': user!=='spiros'}"   href="/jh" role="tab"  aria-selected="false">JH</a>

  <hr style="border-top: 1px solid white; width:80%;">
  <!-- <template v-if="roles.includes('users') || roles.includes('admin')"> -->
  <!-- <a v-if="perms.includes('prox-add-user') || perms.includes('prox-del-user')" :class="{'nav-link': true,'active' : activeTab == 'users'}" id="v-pills-users-tab"  href="/admin?_card=users" role="tab" aria-controls="v-pills-users" aria-selected="false">Users</a> -->
   <a :class="{'nav-link': true,'active' : activeTab == 'addUser', 'disabled': !(perms.includes('users-add-user'))}" id="v-pills-nodes-tab"  href="/adduser" role="tab" aria-controls="v-pills-nodes" aria-selected="false">Users</a>
  <a :class="{'nav-link': true,'active' : activeTab == 'nodes', 'disabled': !(perms.includes('prox-add-user') || perms.includes('prox-del-user'))}" id="v-pills-nodes-tab"  href="/admin?_card=nodes" role="tab" aria-controls="v-pills-nodes" aria-selected="false">User nodes</a>
    <a :class="{'nav-link': true,'active' : activeTab == 'userNcs', 'disabled': !(perms.includes('nextcloud-add-user') || perms.includes('nextcloud-del-user'))}" id="v-pills-nodes-tab"  href="/userncs" role="tab" aria-controls="v-pills-nodes" aria-selected="false">User NC's</a>
  <a :class="{'nav-link': true,'active' : activeTab == 'perms', 'disabled': !(perms.includes('auth-add-usersclaims') || perms.includes('auth-del-usersclaims'))}" id="v-pills-perms-tab"  href="/admin?_card=perms" role="tab" aria-controls="v-pills-perms" aria-selected="false">Permissions</a>
  <a :class="{'nav-link': true,'active' : activeTab == 'groups', 'disabled': !(perms.includes('usergroups-add-user') || perms.includes('usergroups-del-user'))}" id="v-pills-groups-tab" href="/admin?_card=groups" role="tab" aria-controls="v-pills-groups" aria-selected="false">Groups</a>
  <!-- </template> -->
  <!-- <button style="font-size:12px;padding:5px;border-radius:0;" class="btn btn-danger mt-3" @click.prevent="logOut"><i class="fas fa-sign-out-alt"></i> Log Out</button> -->
</div>
          </div>
</template>

<script>
export default {
  name: 'NavbarLeft',
  props: ['roles', 'activeTab', 'perms'],
    data() {
    return {
      user: localStorage.getItem('user'),
      subs: false,
    };
  },
  methods:{
    logOut(){
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      this.$router.push('/login')
    },
    show_subs(){
      this.subs = !this.subs
    }
  },
  mounted(){
    if(this.activeTab == 'iplists' || this.activeTab == 'serversets' || this.activeTab == 'whitelistvm'){
      this.subs = true
    }
  }
}
</script>

<style>

  .nav-left-custom{
    border-right:1px solid black; height:100vh; background-color:#212529; margin-top:0; padding-top:60px; text-align:left;
  }
  @media only screen and (max-width: 600px) {
  .nav-left-custom{
    border-right:1px solid black; font-size:10px; height:120vh;; background-color:#212529; margin-top:0; padding-top:60px; text-align:left;
  }
  .nav-link{
    padding:.2rem .4rem;
  }
  
}
</style>