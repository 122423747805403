import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Wire from '../views/Wire.vue'
import Admin from '../views/Admin.vue'
import Cerberus from '../views/Cerberus.vue'
import IpLists from '../views/IpLists.vue'
import ServerSets from '../views/ServerSets.vue'
import NodeDetails from '../views/NodeDetails.vue'
import VPN from '../views/VPN.vue'
import SSL from '../views/SSL.vue'
import Nextcloud from '../views/Nextcloud.vue'
import NextcloudUsers from '../views/NextcloudUsers.vue'
import ncUsers from '../views/ncUsers.vue'
import addUser from '../views/addUser.vue'
import vpnCerts from '../views/vpnCerts.vue'
import Orders from '../views/Orders.vue'
import WhitelistVm from '../views/WhitelistVm.vue'
import RemoteDesktops from '../views/RemoteDesktops.vue'
import Logs from '../views/Logs.vue'
import JH from '../views/JH.vue'
import VNC from '../views/VNC.vue'
import err403 from '../views/403.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/wire',
        name: 'Wire',
        component: Wire
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin
    },
    {
        path: '/cerberus',
        name: 'Cerberus',
        component: Cerberus
    },
    {
        path: '/iplists',
        name: 'IpLists',
        component: IpLists
    },
    {
        path: '/serversets',
        name: 'ServerSets',
        component: ServerSets
    },
    {
        path: '/whitelistvm',
        name: 'WhitelistVm',
        component: WhitelistVm
    },
    {
        path: '/nodedetails',
        name: 'NodeDetails',
        component: NodeDetails
    },
    {
        path: '/vpn',
        name: 'VPN',
        component: VPN
    },
    {
        path: '/vpncerts',
        name: 'vpnCerts',
        component: vpnCerts
    },
    {
        path: '/ssl',
        name: 'SSL',
        component: SSL
    },
    {
        path: '/nextcloud',
        name: 'Nextcloud',
        component: Nextcloud
    },
    {
        path: '/nextcloud_users_data',
        name: 'NextcloudUsers',
        component: NextcloudUsers
    },
    {
        path: '/userncs',
        name: 'ncUsers',
        component: ncUsers
    },
    {
        path: '/adduser',
        name: 'addUser',
        component: addUser
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders
    },
    {
        path: '/remotedesktops',
        name: 'RemoteDesktops',
        component: RemoteDesktops
    },
    {
        path: '/logs',
        name: 'Logs',
        component: Logs
    },
    {
        path: '/jh',
        name: 'JH',
        component: JH
    },
    {
        path: '/vnc',
        name: 'VNC',
        component: VNC
    },
    {
        path: '/403',
        name: '403',
        component: err403
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router