<template>
<div>
  <div v-if="perms.includes('usergroups-add-user') || perms.includes('usergroups-del-user')" id="addNodeToUser">
    <h4 style="text-align:left" class="mt-2">
      User Groups
      <a
        href="#"
        data-toggle="modal"
        data-target="#apiModal"
        class="btn btn-danger pt-1 pb-1 ml-2"
        style="font-size:14px;"
      >API</a>
    </h4> 
    <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >ADD: {{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>ADD: Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
             
            </div>
          </template>
          <template v-if="show_alert2">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >DEL: {{message2.msg}}<span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span>
              <b>DEL: Error {{message2.code}}:</b>
              {{message2.msg}}
              <br />
              <span v-if="message2.details != null && message2.details != ''">
                <b>Details:</b>
                {{message2.details}}
                <br />
              </span>
              <span v-if="message2.hint != null && message2.hint != ''">
                <b>Hint:</b>
                {{message2.hint}}
              </span>
             
            </div>
          </template>

    <i class="fas fa-cog fa-spin fa-5x" v-if="loading"></i>
    <div v-if="!loading" class="row mt-3">
      <div class="col-md-5">
        <select class="form-control" v-model="selectedGroup" @change="filterUsers">
          <option value selected disabled>Select group</option>
          <option style="font-weight:bold;" value="new">Add New Group</option>
          <template v-for="group in userGroups">
            <option :key="group" :value="group">{{group}}</option>
          </template>
        </select>
        <input
          v-model="newGroupName"
          type="text"
          class="form-control mt-2"
          placeholder="Group name"
          v-if="selectedGroup=='new'"
        />
        
        <button
          v-if="selectedGroup != ''"
          @click="saveGroups"
          class="btn btn-success mt-3"
        >Save changes</button>
      </div>
      <div class="col-md-6">
        <template v-if="oldUsers.length > 0">
        <input type="checkbox" v-model="forceRemoval" class="mt-1 mb-1"> Force Removal
        </template>
        <div
          v-if="selectedGroup !== ''"
          class="table-wrapper-scroll-y custom-scrollbar mt-1"
          style="border: 1px solid black"
        >
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th style="position: sticky; top:0; max-width:20px;" scope="col">Member</th>
                <th style="position: sticky; top:0;" scope="col">Name</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="selectedGroup != ''">
                <template v-for="user in sortedUsers">
                  <template v-if="user != 'swapnil' && user != 'bpo' && user != 'paxton' && user != 'vinay' && user !='bpoaa'">
                  <tr :key="user">
                    <td style="max-width:20px;">
                      <input
                        :id="'u-'+user"
                        name="users"
                        v-model="checkedUsers"
                        :value="user"
                        type="checkbox"
                        @change="findChanges"
                      />
                    </td>
                    <td>{{user}}</td>
                  </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <api-modal
      :title="'User Nodes API'"
      :apiTitles="['GET Users with Groups','Add User to Group','Delete user from group']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/usergroupsUserGet','https://rdapi-data.gnuhost.tech/rpc/usergroupsUserAdd','https://rdapi-data.gnuhost.tech/rpc/usergroupsUserDel']"
      :apiPayload="[{},
      {
          _name: 'username',
          _group: 'group_name'
        },
        {
          _name: 'username',
          _group: 'group_name'
        }
      ]"
    ></api-modal>
  </div>
<div class="mt-5" v-if="!(perms.includes('usergroups-add-user') || perms.includes('usergroups-del-user'))">
          

  </div>
</div>
</template>

<script>
import axios from "axios";
import staticData from "../../data";
import uniq from "lodash/uniq";
import apiModal from "../modals/apiModal.vue";
export default {
  props: ["perms", "users"],
  components: {
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      selectedUser: "",
      userGroups: "",
      selectedGroup: "",
      checkedUsers: [],
      initUsers: [],
      newUsers: [],
      forceRemoval: false,
      oldUsers: [],
      newGroupName: "",
      show_alert: false,
      show_alert2: false,
      message:{
        msg: ''
      },
      message2:{
        msg: ''
      },
      counter_n: 0,
      counter_o: 0,
      loading: false,
      group_not_exist: false
    };
  },
  methods: {
    getData() {
      this.forceRemoval = false;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      axios
        .get(this.basicUrl + "usergroupsUserGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.userGroups = res[0].groups;
          
          if (this.selectedGroup == "new") {
            this.selectedGroup = this.newGroupName;
            this.filterUsers();
          } else if (!this.userGroups.includes(this.selectedGroup)) {
            this.selectedGroup = "";
          }
        });
    },
    filterUsers() {
      this.checkedUsers = [];
      this.initUsers = [];
      this.newUsers = [];
      this.oldUsers = [];
      for (let i = 0; i < this.users.length; i++) {
        if (
          this.users[i].groups.includes(this.selectedGroup) &&
          this.initUsers.indexOf(this.users[i].name) === -1
        ) {
          this.checkedUsers.push(this.users[i].name);
          this.initUsers.push(this.users[i].name);
        }
      }
    },
    findChanges() {
      this.newUsers = [];
      this.oldUsers = [];
      for (let i = 0; i < this.checkedUsers.length; i++) {
        if (this.initUsers.indexOf(this.checkedUsers[i]) === -1) {
          this.newUsers.push(this.checkedUsers[i]);
        }
      }
      for (let i = 0; i < this.initUsers.length; i++) {
        if (this.checkedUsers.indexOf(this.initUsers[i]) === -1) {
          this.oldUsers.push(this.initUsers[i]);
        }
      }
    },
    saveGroups() {
      this.counter_n = 0;
      this.counter_o = 0;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      for (let i = 0; i < this.newUsers.length; i++) {
       
        let v_group = "";
        if (this.selectedGroup == "new") {
          v_group = this.newGroupName;
        } else {
          v_group = this.selectedGroup;
        }
        let payload = {
          _name: this.newUsers[i],
          _group: v_group
        };

        axios
          .post(this.basicUrl + "rpc/usergroupsUserAdd", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("ADD:", res);
            this.counter_n = this.counter_n + 1;
            this.message.msg = res;
            this.alert_type = "success";
            this.show_alert = true;
            if (
              this.counter_n + this.counter_o ==
              this.newUsers.length + this.oldUsers.length
            ) {
              this.$emit("reloadUsers");
              setTimeout(() => this.filterUsers(), 2000);
            }
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
      }
      for (let i = 0; i < this.oldUsers.length; i++) {
      
        var payload = {}

        if(this.forceRemoval){
         payload = {
            _name: this.oldUsers[i],
          _group: this.selectedGroup,
          force: true
          }
        }
       else{
        payload = {
          _name: this.oldUsers[i],
          _group: this.selectedGroup
        };}

        axios
          .post(this.basicUrl + "rpc/usergroupsUserDel", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("DEL:", res);
            this.counter_o = this.counter_o + 1;
            this.counter_n = this.counter_n + 1;
            this.message2.msg = res;
            this.alert_type = "success";
            this.show_alert2 = true;
            if (
              this.counter_n + this.counter_o ==
              this.newUsers.length + this.oldUsers.length
            ) {
              this.$emit("reloadUsers");
              setTimeout(() => this.filterUsers(), 2000);
            }
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message2.msg = error.response.data.message;
          this.message2.hint = error.response.data.hint;
          this.message2.details = error.response.data.details;
          this.message2.code = error.response.data.code;
          this.show_alert2 = true;
        });
      }
      setTimeout(() => this.getData(), 3200);
    }
  },
  computed: {
    sortedUsers() {
      return uniq(this.users.map(({ name }) => name));
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
<style>
</style>