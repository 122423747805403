var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('menu-top'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1"},[_c('Menu',{attrs:{"roles":_vm.roles,"activeTab":"ssl","perms":_vm.permissions}})],1),(_vm.permissions.includes('ssl-add-cert'))?_c('div',{staticClass:"col-md-5 custom-col-wire ml-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vnc_node),expression:"vnc_node"}],attrs:{"type":"text","placeholder":"NODE"},domProps:{"value":(_vm.vnc_node)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vnc_node=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vnc_vmid),expression:"vnc_vmid"}],attrs:{"type":"text","placeholder":"VMID"},domProps:{"value":(_vm.vnc_vmid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vnc_vmid=$event.target.value}}}),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.generateVNC}},[_vm._v("START VNC")])]):_vm._e()])],_c('api-modal',{attrs:{"title":'Wire API',"apiTitles":['GET IPs','Add IP','Remove IP'],"apiType":['GET','POST','POST'],"apiUrl":['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel'],"apiPayload":[{},
    {
      _ip: '66.66.66.56'
    },
    {
      _ip: '66.66.66.56'
    }
    ]}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }