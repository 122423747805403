<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="" :perms="permissions"></Menu>
        </div>
        
        <div class="col-md-6 custom-col-wire ml-3">
          <template v-if="show_alert1">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert1=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert1=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
             
            </div>
          </template>

          <button class="btn btn-success mb-3" @click="downloadZip()">Download all</button><br>
          <input v-model="search" type="text" id="searchInput" placeholder="Search" class="mt-2 mb-2">
            <table class="table" style="background-color:white;text-align:left;">
              <thead>
                <tr>
                  <th scope="col">NAME</th>
                  <th scope="col">NOTE</th>
                  <th scope="col">CERTIFICATE</th>
                  <th scope="col">MAC</th>
                  <th scope="col">DELETE CERT</th>
                </tr>
              </thead>
              <tbody style="text-align:left;">
                <template>
                  <template v-for="(cert,index) in filteredList">
                    
                      <tr :key="index">
                        <td>{{cert.name}}</td>
                        
                        <td>
                            <template v-if="cert.notes !== '' && cert.notes !== null && cert.notes !== 'null'">
                              
                               <template v-if="editCertNote == 'e-'+index">
                                 <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="cert.notes" @keyup.enter="saveNote(cert,index)">
                              </template>
                              <template v-else>
                                <a href="#" @click.prevent="editCertNote='e-'+index">{{cert.notes}}</a>
                              </template>
                            </template>
                            <template v-else>
                              
                            <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="cert.notes" @keyup.enter="saveNote(cert,index)">
                            </template>
                          </td>
                        <td><a v-on:click="download(cert.name)" :href="certUrl" :download="certName">DOWNLOAD</a></td>
                      <td><button style="padding-top:3px; padding-bottom:3px; font-size:13px;" class="btn btn-primary" data-toggle="modal" data-target="#macModal" @click="setCurrentCert(cert)">Whitelist</button></td>
                      <td><a href="" @click.prevent="removeCert(cert.name, cert.hostname)" style="color:red;">DELETE</a></td>
                      </tr>
                   
                  </template>
                </template>
              </tbody>
            </table>
          
        </div>
      </div>
    </template>
    <api-modal
      :title="'Wire API'"
      :apiTitles="['GET IPs','Add IP','Remove IP']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel']"
      :apiPayload="[{},
      {
        _ip: '66.66.66.56'
      },
      {
        _ip: '66.66.66.56'
      }
      ]"
    ></api-modal>
    <mac-modal
    :title="selectedCertTitle"
    :data="selectedCert"
    @refreshData="setCurrentCert($event)"
    >
    </mac-modal>

  </div>
</template>

<script>
import JSZip from 'jszip'
import saveAs from 'file-saver'
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
import apiModal from "../components/modals/apiModal.vue";
import macModal from "../components/modals/WhitelistMAC.vue";
export default {
  name: "vpnCerts",
  components: {
    Menu,
    MenuTop,
    apiModal,
    macModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      certUrl: '',
      certName: '',
      message: {
        msg: "Server did not return any message"
      },
      data: [],
      show_alert1: false,
      alert_type: "",
      search: "",
      start_loading: false,
      claims: null,
      roles: [],
      editCertNote: '',
      currentVpn: '',
      selectedCert: [],
      selectedCertTitle: '',
      currentCert: [],
      currentCertDecoded: '',
      permissions: []
    };
  },
  methods: {
    copyTextArea() {
      this.$refs.crt.select();
      document.execCommand('copy');
    },
    setCurrentCert(cert){
      this.selectedCert = []
      this.selectedCertTitle = []
      console.log('cert', cert)
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      axios
        .get(this.basicUrl + "vpnOvpnWlmacGet?hostname=eq."+cert.hostname+"&common_name=eq."+cert.name, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log('RES', res);
          this.selectedCert = res;
          this.selectedCertTitle = cert
        });
    },
    
    getData() {
      this.editCertNote = ''
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
  
        axios
        .get(this.basicUrl + "vpnOvpnGet?hostname=eq."+this.currentVpn, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.data = res;
        });
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

          if(!this.permissions.includes('vpn-get-ovpn')){
        this.$router.push("/403")
      }
          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    saveNote(cert,index){
      let currentVal = document.getElementById('note-'+index).value;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _hostname: cert.hostname,
        _name: cert.name,
        _notes: currentVal
      }
      console.log(payload)
      axios
        .post(this.basicUrl + "rpc/vpnOvpnNotesSet",payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.getData()
        });
    },
    removeCert(cert,host){
      if(confirm("Are you sure you want to permanently delete certificate: " + cert + "?")){
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _common_name: cert,
        _hostname: host
      };
      axios
        .post(this.basicUrl + "rpc/vpnOvpnDel", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert1 = true;
         this.getData();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert1 = true;
          
        });
      }
    },
    getDecodedCert(cert_name){
      this.currentCertDecoded = ''
      for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].name == cert_name) {
              this.currentCert = this.data[i];
            }
          }
      this.currentCertDecoded = atob(this.currentCert.ovpn)
    },
    download: function(cert_name) {
      this.getDecodedCert(cert_name)
      const certData = encodeURIComponent(this.currentCertDecoded)
      this.certUrl = `data:text/plain;charset=utf-8,${certData}`
      this.certName = cert_name + '.ovpn'
    },
    showCert(cert_name){
      this.currentCertDecoded = ''
      setTimeout(() => (this.getDecodedCert(cert_name)), 800);
      
    },
    downloadZip(){
      var zip = new JSZip();
      var decoded = ''
      var name = this.data[0].hostname + '.zip'
      for (let i = 0; i < this.data.length; i++) {
            decoded = atob(this.data[i].ovpn)
            zip.file(this.data[i].name+'.ovpn', decoded)
          }
      zip.generateAsync({type:"blob"})
        .then(function(content) {
        // Force down of the Zip file
          saveAs(content, name);
        });
    }
    
  },
  computed: {
    filteredList() {
      return this.data.filter(item => {
        return item.name.includes(this.search)
      })
    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.currentVpn = this.$route.query.host
    window.document.title = this.$route.query.host + ' - CERTS'
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
