<template>
<div>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="z-index:2; position:fixed; width:100%;">
  <a class="navbar-brand" href="#">RD-API</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <!-- <li class="nav-item">
        <a v-if="roles.includes('prox-vm')" class="nav-link" href="/">VM_Panel <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a v-if="roles.includes('wire')" class="nav-link" href="/wire">Wire</a>
      </li>
      <li class="nav-item">
        <a v-if="roles.includes('admin') || roles.includes('manager') || roles.includes('users')" class="nav-link" href="/admin">Admin</a>
      </li>
      <li class="nav-item">
        <a v-if="roles.includes('cerberus')" class="nav-link" href="/cerberus">Cerberus</a>
      </li> -->
      
    </ul>
    <ul class="navbar-nav ml-auto">
            <li class="nav-item">
               <span class="navbar-text" style="color:white">
                 {{user}}
              </span>
            </li>
            <li class="nav-item">
                <button style="font-size:12px;padding:5px;" class="btn btn-danger ml-3 mt-1" @click.prevent="logOut"><i class="fas fa-sign-out-alt"></i> Log Out</button>
            </li>

        </ul>

  </div>
</nav>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  // props: ['roles'],
    data() {
    return {
      user: localStorage.getItem('user')
    };
  },
  methods:{
    logOut(){
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      this.$router.push('/login')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fixed-nav{
  position: fixed;
  width: 100%;
  z-index: 1;
  margin-bottom:100px;
}
</style>
