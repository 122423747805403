<template>
  <div class="panel-new">
    <div class="ml-4">
      <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success mt-2"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
     <table class="table mt-2 table-responsive">
       <thead class="thead-dark">
         <th>Prox</th>
        <th data-toggle="modal" data-target="#tableDataModal" @click="getCurrentData('url')" style="cursor:pointer;">URL</th>
         <th data-toggle="modal" data-target="#tableDataModal" @click="getCurrentData('ip')" style="cursor:pointer;">IP</th>
         <th>ONLINE</th>
         <th>FIREWALL</th>
         <th>VMs</th>
         <th>OFFICE</th>
         <th>VLAN</th>
         <th>ROUTER</th>
         <th>HEARTBEAT</th>
       </thead>
       <tbody>
        <template v-for="node in nodes_data">
      <item :perms="perms" :roles="roles" @restartFW="current_fw2=$event;restartFirewall()" @reloadFW="current_fw=$event;reloadFirewall()" @refAll="refreshAll" @currentNode="currentWhitelist=$event; getData(); currentNodeData=$event;" @actNode="currentActNode=$event" @actVm="currentActVm=$event" :single_node_data='node' :key="node.prox"></item>
    </template>
       </tbody>
     </table>
<whitelist-modal @purge="purgeData()" @loadIpSet="currentList=$event; getData()" @list_changed="getData()" :prox_data="currentWhitelist" :perms="perms" :ip_list="ips" :start_loading="startLoad"></whitelist-modal>
<vm-details-modal :node_data="currentNodeData" @actNode="currentActNode=$event" @actVm="currentActVm=$event"></vm-details-modal>
<kvm-modal :current_act_node="currentActNode" :current_act_vm="currentActVm" :key="currentActNode+currentActVm"></kvm-modal>
<table-data-modal :data="current_data"></table-data-modal>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import staticData from '../../data'
import Item from '@/components/panelViews/newElements/item.vue'
import whitelistModal from '../modals/whitelistModal'
import vmDetailsModal from '../modals/vmDetailsModal'
import tableDataModal from '../modals/tableDataModal'
import kvmModal from '../modals/kvmModal'
export default {
  name: 'PanelNew',
  props: ['nodes_data','perms','roles'],
  components: {
     Item,
     whitelistModal,
     vmDetailsModal,
     tableDataModal,
     kvmModal
  },
  data() {
    return {
      currentWhitelist: '',
      whitelistData: [],
      current_data:[],
      basicUrl: staticData.basicUrl,
      ips: [],
      token: localStorage.getItem('token'),
      startLoad: false,
      currentList: "",
      show_alert: false,
      message:{
        msg: ''
      },
      current_fw: '',
      current_fw2: '',
      currentNodeData: [],
      currentActNode: null,
      currentActVm: null,
    };
  },
  methods:{
     getCurrentData(type) {
       this.current_data = []
       for (let i = 0; i < this.nodes_data.length; i++) {
         if(type == 'ip'){
           this.current_data.push(this.nodes_data[i].ip)
         }
         if(type == 'url'){
           this.current_data.push(this.nodes_data[i].url)
         }
          
        }
     },
     getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      
      let data={_ipset: this.currentList}
      if(this.currentList != "" && this.currentList != null){
        this.startLoad = true;
      axios
        .post(this.basicUrl + 'rpc/proxIpsetGet', data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.ips = res.ips;
          this.startLoad = false;
          this.currentList = ""
        })
        .catch(error => {
          this.currentList = ""
          console.log(error)
        });
    }},
    purgeData(){
      this.currentList = ""
      this.ips = []
    },
    refreshAll(){
      this.$emit('refreshAll', true)
    },
    restartFirewall(){
       let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
       let data = {
         _prox: this.current_fw2
       }
       
      axios.post( 
        this.basicUrl + 'rpc/restartFirewall', data,
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          alert("FIREWALL IS RESTARTING, IT MAY TAKE UP TO 2 MINUTES")
          
        }).catch((error) => {
        this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert3 = true;
        });
    },
    reloadFirewall(){
       let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
       let data = {
         _prox: this.current_fw
       }
       
      axios.post( 
        this.basicUrl + 'rpc/reloadFirewall', data,
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          alert("FIREWALL IS RELOADING, IT MAY TAKE UP TO 2 MINUTES")
          
        }).catch((error) => {
        this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert3 = true;
        });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
 .panel{
   padding:10px;
 }
 .item_card{
   display: inline-block;
   margin-left:5px;
   margin-top:5px;
   font-size:12px;
   text-align: left;
 }

 #accordionNodes{
   margin-top:20px;
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
 }
</style>
