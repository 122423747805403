<template>
  <div>
    <div
      class="modal fade"
      id="vpnNotesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vpnNotesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog dialog-custom" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="vpnNotesModalLabel">{{data.hostname}} Notes</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body px-2 pt-3 pb-3" style="text-align:left">
            <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}} <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>{{current_note}}
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
            </div>
          </template>
              <textarea class="form-control" v-model="current_note" style="height:400px;"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveNote()" class="btn btn-success">Save</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="reset">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import staticData from "../../data";
import axios from "axios"
export default {
  name: "vpnNotes",
  props: ["data"],
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem('token'),
      current_note: '',
      show_alert: false,
      alert_type: '',
      message:{
        msg: ''
      }
    };
  },
  watch:{
    data: function(){
      this.current_note = this.data.notes
    }
  },
  methods: {
    reset(){
      this.show_alert = false;
    },
    saveNote() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload = {
        _hostname: this.data.hostname,
        _notes: this.current_note,
        _ip: this.data.ip
      }
        axios
        .post(this.basicUrl + "rpc/vpnServerSet", payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          this.current_note = ''
          this.$emit('reloadData', true)
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
          this.current_note = ''
        });
    
    
    },
  },
  
  mounted() {
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s_vm span {
  font-size: 12px;
  padding: 0;
  padding-top: 10px;
}
.s_vm button{
    padding:2px;
    font-size:10px;
}
.s_vm_details {
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
}
.dialog-custom{
    width: 90% !important;
    max-width:90% !important;
}
.s_vm hr {
  margin: 0;
  padding: 0;
}
.s_vm_head {
  background-color: lightsteelblue;
 
}
.modal-body p {
    word-wrap: break-word;
}
</style>
