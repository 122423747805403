<template>
  <div class="home">
    <menu-top></menu-top>
    <div class="row">
      <div class="col-md-1">
    <Menu :roles="roles" activeTab="vm_list" :perms="permissions"></Menu>
      </div>
      <div class="col-md-10 custom-col" style="">
    <div class="panel_nav" v-if="permissions.includes('prox-vm-list')">
      
      <div class="fl-r">
        
         <a href="/remotedesktops" class="btn btn-primary pt-1 pb-1 mr-2" style="font-size:14px;">RD Conenction Strings</a>
          <a href="/logs" class="btn btn-secondary pt-1 pb-1 mr-2" style="font-size:14px;">Logs</a>
        <a href="/orders" class="btn btn-success pt-1 pb-1 mr-2" style="font-size:14px;"> New Order</a>
        <a href="#" data-toggle="modal" data-target="#apiModal"  class="btn btn-danger pt-1 pb-1" style="font-size:14px;"> API</a>
      <button class="btn btn-secondary" @click="changeView('classic')"><i class="fas fa-th"></i> Grid View</button>
      <button class="btn btn-primary" @click="changeView('new')"><i class="fas fa-bars"></i> Summary View</button>
      <button class="btn btn-warning" @click="loadData"><i class="fas fa-spinner"></i> Reload data</button>
      </div>
      <input v-model="search" type="text" id="searchInput" placeholder="Search Node" style="" class="">  
    </div>
    <div class="view">
         <div v-if="blocked" class="alert alert-danger">
           <p>{{block_msg.code}}: {{block_msg.message}}</p>
           <p><b>{{block_msg.hint}}</b></p>
           <p><i>{{block_msg.details}}</i><br></p>
           
         </div>
      <div class="content" v-if="!loading">
      <div v-if="view == 'classic'">
        <classic-view @refreshAll="refreshData" :nodes_data='filteredList' :perms="permissions" :roles="roles"></classic-view>
      </div>

      <div v-if="view == 'new'">
        <new-view @refreshAll="refreshData" :nodes_data='filteredList' :perms="permissions" :roles="roles"></new-view>
      </div>
      </div>
      <div class="loader">
      <i class="fas fa-cog fa-spin fa-5x" v-if="loading && !blocked"></i>
      </div>
    </div>
      </div>
  </div>
  
  <api-modal
      :title="'Servers API'"
      :apiTitles="['GET Prox Nodes']"
      :apiType="['GET']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/proxNodesGet']"
      :apiPayload="[{}
      ]"
    ></api-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Menu from '@/components/NavbarLeft.vue'
import MenuTop from '@/components/Navbar.vue'
import ClassicView from '@/components/panelViews/classicView.vue'
import NewView from '@/components/panelViews/newView.vue'
import apiModal from "../components/modals/apiModal.vue";
import staticData from '../data'

export default {
  name: 'Home',
  components: {
     Menu,
     ClassicView,
     NewView,
     MenuTop,
     apiModal
  },
   data() {
    return {
      view: 'classic',
      apiUrl: staticData.basicUrl,
      token: localStorage.getItem('token'),
      nodes: [],
      claims: null,
      blocked: false,
      block_msg:{},
      permissions: [],
      roles: [],
      loading: false,
      search: ''
    };
  },
  methods:{
    changeView(type){
      this.loading = true
      this.view = type
      setTimeout(() => this.loading = false, 1000);
    },
    loadData(){
      this.loading = true
      let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      axios.get( 
        this.apiUrl + 'proxNodesGet',
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          this.nodes = res
          console.log(res)
          
        }).catch((error) => {
        if( error.response ){
        
        this.block_msg.code = error.response.data.code;
        this.block_msg.message = error.response.data.message;
        this.block_msg.details = error.response.data.details;
        this.block_msg.hint = error.response.data.hint;
        this.blocked = true;
    }
        });
    
    axios.get( 
        this.apiUrl + 'authClaimsGet?name=eq.'+localStorage.getItem('user'),
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res

          for (let i = 0; i < this.claims.length; i++){
            this.permissions.push(this.claims[i].claim)
            if(this.roles.indexOf(this.claims[i].role) === -1){
              this.roles.push(this.claims[i].role)
            }
            
          }

          this.loading = false
        }).catch((error) => {
        if( error.response ){
         console.log(error.response.data)
       
    }
});
    },
       refreshData(){
      let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      axios.get( 
        this.apiUrl + 'proxNodesGet',
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          this.nodes = res
        }).catch((error) => {
        if( error.response ){
         console.log(error.response.data)
       
    }
});
    },
 
  }, 
  computed: {
    filteredList() {
      return this.nodes.filter(item => {
        return item.url.includes(this.search)
      })
    }
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push("/login");
    }
    else{
      this.loadData()
      
       this.$nextTick(function () {
            window.setInterval(() => {
                this.refreshData();
            },60000);
        })
    }
   
  }
}
</script>

<style>
.loader{
 margin: auto;
  width: 50%;
  padding: 10px;
}
table{
  font-family: "Comic Mono";
}
.panel_nav{
  text-align: left;
  margin-top:10px;
  margin-left:20px;
}
.panel_nav button{
  margin-right:5px;
  margin-left:5px;
  padding:3px;
  font-size:14px;
}
.panel_nav .nav-right{
  text-align:right;
}

.fl-r{
  float:right;
}

.custom-col{
  padding:0; position:relative; left:50px; margin-top:60px;
}


@media only screen and (max-width: 600px) {
  .custom-col{
  padding:0; position:relative; left:80px; margin-top:60px;
}
.fl-r{
  float:none;
  margin-bottom:10px;
}
.fl-r .btn{
  margin:1px;;
  font-size:10px;
  font-weight:bold;
}
}
</style>
