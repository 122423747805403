<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="whitelistModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Whitelist {{prox_data.url}} </h5>

            
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <i v-if="start_loading" class="fas fa-cog fa-spin fa-2x"></i>
            <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}}</div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
            </div>
          </template>
            <template v-if="!start_loading">
              <div class="whitelisting pb-3 pt-3">
                <div class="row mb-2" style="text-align:left;">
                  <div class="col-md-6">
                  <select @change="emitSet()" class="ml-1" v-model="selectedIpSet">
                    <option value="" selected disabled>Select IpSet</option>
                    <template v-for="(list,index) in prox_data.ipsets">
                      <option v-if="list != 'gnuhost.iplist'" :key="index" :value="list">{{list}}</option>
                    </template>
                  </select>
                  </div>
                </div>
                <div class="row" v-if="selectedIpSet != ''">
                  <div v-if="perms.includes('prox-fw-add-ip')" class="col-md-6">
                    <input type="text" placeholder="255.255.255.255" v-model="current_ip">
                    <button class="ml-1" @click="addIP()">Add IP</button>
                  </div>
                  <div v-if="perms.includes('prox-fw-get-ip')" class="col-md-6">
                    <input v-model="search" type="text" id="searchInput" placeholder="Search">
                  </div>
                </div>
               
              </div>
              
              <div class="table-wrapper-scroll-y custom-scrollbar">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">IP</th>
                      <th v-if="perms.includes('prox-fw-del-ip')" scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="ip in filteredList">
                      <template v-if="ip != ''">
                        <tr :key="ip">
                          <td v-if="perms.includes('prox-fw-get-ip')">{{ip}}</td>
                          <td v-if="perms.includes('prox-fw-del-ip')">
                            <a href @click.prevent="removeIP(ip)">
                              <i class="red fas fa-minus-circle"></i>
                            </a>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="emitPurge()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import staticData from "../../data";
export default {
  name: "Whitelist",
  props: ["prox_data", "ip_list", "start_loading", "perms"],
  components:{
 
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ip: "127.0.0.1",
      port: "",
      current_ip: "",
      token: localStorage.getItem('token'),
      validation: false,
      selectedIpSet: "",
      message: {
        msg: 'Server did not return any message'
      },
      show_alert: false,
      search: ''
    };
  },
  methods: {
    onIpChange(ip, port, valid) {
      if (valid) {
        this.current_ip = ip;
        console.log(port);
      }
      this.validation = valid;
    },
    emitSet(){
      this.$emit('loadIpSet',this.selectedIpSet)
    },
    emitPurge(){
      this.$emit('purge',true)
      this.selectedIpSet = ""
    },
    removeIP(ip) {
      let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      
      let data={_ipset: this.selectedIpSet, _ip: ip}
        axios
        .post(this.basicUrl + "rpc/proxIpsetIpDel", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          setTimeout(() => (this.show_alert = false), 3000);
          this.emitSet();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
    },
    addIP() {
  let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      
      let data={_ipset: this.selectedIpSet, _ip: this.current_ip}
        axios
        .post(this.basicUrl + "rpc/proxIpsetIpAdd", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          setTimeout(() => (this.show_alert = false), 3000);
          this.emitSet();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
      
    }
  },
  computed: {
    filteredList() {
      return this.ip_list.filter(item => {
        return item.includes(this.search)
      })
    }
  },
  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
  font-size: 12px;
}
.custom-scrollbar .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.close {display: none;}
</style>
