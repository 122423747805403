<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="nextcloud" :perms="permissions"></Menu>
        </div>
        
        <div class="col-md-10 custom-col-wire ml-3" v-if="permissions.includes('nextcloud-get-users')">
            <table class="table" style="background-color:white;text-align:left;">
              <thead>
                <tr>
                  <th scope="col">URL</th>
                  <th scope="col">IP</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">NOTES</th>
                  <th scope="col">TOTAL</th>
                  <th scope="col">USED</th>
                  <th scope="col">FREE</th>
                  <th scope="col">USED %</th>
                  
                  <!-- <th scope="col">UPDATE_DT</th> -->
                </tr>
              </thead>
              <tbody style="text-align:left;">
                <template>
                  <template v-for="(nc,index) in Nextclouds">
                    
                      <tr :key="index">
                        <td><a :href="'/nextcloud_users_data?ip='+nc.ip+'&name='+nc.url">{{nc.url}}</a></td>
                        
                        <td>{{nc.ip}}</td>
                        <td> <template v-if="nc.status=='online'"> 
                          <i class="fas fa-circle green"></i>
                           </template> 
                          <template v-else>
                              <i class="fas fa-circle red"></i>
                        </template></td>
                        <td><template v-if="nc.notes !== '' && nc.notes !== null && nc.notes !== 'null'">
                              
                               <template v-if="editNote == 'e-'+index">
                                 <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="nc.notes" @keyup.enter="saveNote(nc,index)">
                              </template>
                              <template v-else>
                                <a href="#" @click.prevent="editNote='e-'+index">{{nc.notes}}</a>
                              </template>
                            </template>
                            <template v-else>
                              
                            <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="nc.notes" @keyup.enter="saveNote(nc,index)">
                            </template></td>
                            <td>{{nc.Total}}GB</td>
                        <td>{{nc.Used}}GB</td>
                        <td>{{nc.Free}}GB</td>
                        <td>{{nc.Percent}}%</td>
                        
                        <!-- <td>{{nc.update_dt.split('+')[0].replace('T',' ').split('.')[0]}}</td> -->
                        
                      </tr>
                   
                  </template>
                </template>
              </tbody>
            </table>
          
        </div>
      </div>
    </template>
   
    <api-modal
      :title="'Wire API'"
      :apiTitles="['GET IPs','Add IP','Remove IP']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel']"
      :apiPayload="[{},
      {
        _ip: '66.66.66.56'
      },
      {
        _ip: '66.66.66.56'
      }
      ]"
    ></api-modal>

  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
import apiModal from "../components/modals/apiModal.vue";
export default {
  name: "Nextcloud",
  components: {
    Menu,
    MenuTop,
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ip: "127.0.0.1",
      port: "",
      current_ip: "",
      validation: false,
      a_token: localStorage.getItem("token"),
      message: {
        msg: "Server did not return any message"
      },
      data: [],
      show_alert: false,
      alert_type: "",
      search: "",
      start_loading: false,
      claims: null,
      roles: [],
      editNote: '',
      Nextclouds: [],
      currentServer: [],
      permissions: []
    };
  },
  methods: {
    getData() {
      this.editNote = ''
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
  
        axios
        .get(this.basicUrl + "nextcloudServersGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.Nextclouds = res;
        });
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }
          if(!this.permissions.includes('nextcloud-get-users')){
        this.$router.push("/403")
      }
          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    saveNote(server,index){
      let currentVal = document.getElementById('note-'+index).value;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _ip: server.ip,
        _notes: currentVal,
        
      }
      axios
        .post(this.basicUrl + "rpc/nextcloudNoteSet",payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.getData()
        });
    },
  },
  computed: {
   
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    else{
      this.getData();
      
    }
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
