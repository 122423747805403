<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="vpn" :perms="permissions"></Menu>
        </div>
        
        <div class="col-md-10 custom-col-wire ml-3" v-if="permissions.includes('vpn-get-server')">
          <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success mt-2"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
          <div style="text-align:left;" class="mb-2">
          <input v-model="search" type="text" id="searchInput" placeholder="Search" style="" class="">
          </div>
            <table class="table" style="background-color:white;text-align:left;">
              <thead>
                <tr>
                  <th scope="col">HOSTNAME</th>
                  <th scope="col">IP</th>
                  <th scope="col">PROJECT</th>
                  <th scope="col">STATUS</th>
                  <th scope="col" v-if="permissions.includes('vpn-get-ovpn')">CERTS</th>
                  
                  <th scope="col">NOTES</th>
                  <!-- <th scope="col">WHITELIST</th> -->
                  <th scope="col"> </th>
                  
                </tr>
              </thead>
              <tbody style="text-align:left;">
                <template>
                  <template v-for="(server,index) in filteredList">
                    
                      <tr :key="index">
                        <td>  {{server.hostname}}</td>
                        
                        <td>{{server.ip}}</td>
                        <td>
                          <template v-if="server.project !== '' && server.project !== ' ' && server.project !== null && server.project !== 'null'">
                              
                               <template v-if="editProject == 'p-'+index">
                                 <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'project-'+index" :value="server.project" @keyup.enter="saveProject(server,index)" @blur="saveProject(server,index)">
                              </template>
                              <template v-else>
                                <a href="#" @click.prevent="editProject='p-'+index">{{server.project}}</a>
                              </template>
                            </template>
                            <template v-else>
                              
                            <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'project-'+index" :value="server.project" @keyup.enter="saveProject(server,index)" @blur="saveProject(server,index)">
                            </template>
                          <!-- <a href="#" data-toggle="modal" data-target="#vpnNotesModal"  class="" @click="loadServerData(server.hostname)" style="padding:5px; font-size:12px;"> Notes</a> -->
</td>
                        <td>
                         <template v-if="server.online"> 
                          <i class="fas fa-circle green"></i>
                           </template> 
                          <template v-else>
                              <i class="fas fa-circle red"></i>
                        </template>
                        </td>
                        <td v-if="permissions.includes('vpn-get-ovpn')">
                          <a :href="'/vpncerts?host='+server.hostname" target="_blank">{{server.certs}}</a>
                        </td>
                        
                        <td>
                          <template v-if="server.notes !== '' && server.notes !== ' ' && server.notes !== null && server.notes !== 'null'">
                              
                               <template v-if="editNote == 'e-'+index">
                                 <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="server.notes" @keyup.enter="saveNote(server,index)" @blur="saveNote(server,index)">
                              </template>
                              <template v-else>
                                <a href="#" @click.prevent="editNote='e-'+index">{{server.notes}}</a>
                              </template>
                            </template>
                            <template v-else>
                              
                            <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="server.notes" @keyup.enter="saveNote(server,index)" @blur="saveNote(server,index)">
                            </template>
                          <!-- <a href="#" data-toggle="modal" data-target="#vpnNotesModal"  class="" @click="loadServerData(server.hostname)" style="padding:5px; font-size:12px;"> Notes</a> -->
</td>
<!-- <td>
<toggle-button :value="true" :labels="{checked: 'ON', unchecked: 'OFF'}" @change="toggleWL(server.ip, $event)"/>
</td> -->
<td>
  <a href="" @click.prevent="restartVPN(server.ip)">Layer8 Problem Solver</a>
</td>

                      </tr>
                   
                  </template>
                </template>
              </tbody>
            </table>
          
        </div>
      </div>
    </template>
  
    <api-modal
      :title="'Wire API'"
      :apiTitles="['GET IPs','Add IP','Remove IP']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel']"
      :apiPayload="[{},
      {
        _ip: '66.66.66.56'
      },
      {
        _ip: '66.66.66.56'
      }
      ]"
    ></api-modal>

    <vpn-notes-modal @reloadData="loadServerData(currentServer.hostname)" :data="currentServer"></vpn-notes-modal>
  </div>
</template>

<script>
// import { ToggleButton } from 'vue-js-toggle-button'
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
import apiModal from "../components/modals/apiModal.vue";
import vpnNotesModal from "../components/modals/vpnNotesModal.vue";
export default {
  name: "VPN",
  components: {
    Menu,
    MenuTop,
    apiModal,
    vpnNotesModal,
    // ToggleButton
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ip: "127.0.0.1",
      port: "",
      fw_on_off: true,
      current_ip: "",
      validation: false,
      a_token: localStorage.getItem("token"),
      data: [],
      a_user: localStorage.getItem("user"),
      show_alert: false,
      alert_type: "",
      search: "",
      start_loading: false,
      claims: null,
      roles: [],
       message:{
        msg: ''
      },
      editNote: '',
      editProject: '',
      vpnServers: [],
      currentServer: [],
      permissions: []
    };
  },
  methods: {
    getData() {
      this.editNote = ''
      this.editProject = ''
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
      if(this.a_user == "sm_tie" || this.a_user == "da_tie"){
        axios
        .get(this.basicUrl + "vpnServersGet?or=(hostname.like.v-mlyef00-1)", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.vpnServers = res;
        });
      }
      else{
        axios
        .get(this.basicUrl + "vpnServersGet?hostname=not.like.v-mlyef00-1", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.vpnServers = res;
        });
      }
        
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

          if(!this.permissions.includes('vpn-get-server')){
        this.$router.push("/403")
      }

          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    saveNote(server,index){
      let currentVal = document.getElementById('note-'+index).value;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _hostname: server.hostname,
        _ip: server.ip,
        _project: server.project,
        _notes: currentVal
      }
     console.log(payload)
      axios
        .post(this.basicUrl + "rpc/vpnServerSet",payload, config)
        .then(response => {
          
          return response.data;
        })
        .then(res => {
          console.log(res);
           
          this.getData()
        });
    },
    saveProject(server,index){
      let currentVal2 = document.getElementById('project-'+index).value;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _hostname: server.hostname,
        _ip: server.ip,
        _project: currentVal2
      }
     console.log(payload)
      axios
        .post(this.basicUrl + "rpc/vpnServerSet",payload, config)
        .then(response => {
          
          return response.data;
        })
        .then(res => {
          console.log(res);
           
          this.getData()
        });
    },
    restartVPN(ip){
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _ip: ip
      }
      axios
        .post(this.basicUrl + "rpc/vpnServerConfigL8PS",payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          console.log(res);
          this.message.msg = "PERFORMING MAINTENANCE TASK, DON'T TOUCH THIS SERVER FOR THE NEXT 2 MINUTES";
          this.alert_type = "success";
          this.show_alert = true;
        });
    },
    toggleWL(host, event){
      console.log(host + ',' + event.value)
    },
    inputEdited(index){
      let currentInput = document.getElementById('note-'+index);
      currentInput.style.border = "1px solid red"
    },
    loadServerData(host) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
  
        axios
        .get(this.basicUrl + "vpnServersGet?hostname=eq." + host, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.currentServer = res[0];
        });
    
    
    },
  },
  computed: {
    filteredList() {
      return this.vpnServers.filter(item => {
        return item.ip.includes(this.search) || item.hostname.includes(this.search);
      });
    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
