<template>
  <div>
    <div
      class="modal fade"
      id="apiModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="apiModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog dialog-custom" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="apiModalLabel">{{title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body px-2" style="text-align:left">
              <template v-for="(url, index) in apiUrl">
                  <template v-if="apiType[index] == 'POST'">
                      <h5 class="mt-3" :key="index">{{apiTitles[index]}}</h5>
          <p :key="'p'+index">curl -d '{{apiPayload[index]}}' -H "Content-Type: application/json" <span v-if="title != 'Login Api'">-H "Authorization: Bearer {{a_token}}"</span> -X {{apiType[index]}} {{url}}</p>
                  </template>
                  <template v-if="apiType[index] == 'GET'">
                      <h5 :key="'n'+index">{{apiTitles[index]}}</h5>
                    <p :key="index">curl {{url}} -H "Accept: application/json" -H "Authorization: Bearer {{a_token}}"</p>
                  </template>
                  
              </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import staticData from "../../data";
export default {
  name: "Details",
  props: ["title", "apiPayload", "apiUrl", "apiType", "apiTitles"],
  data() {
    return {
      cgiUrl: staticData.cgiUrl,
      a_token: localStorage.getItem('token')
    };
  },
  methods: {
    
  },
  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s_vm span {
  font-size: 12px;
  padding: 0;
  padding-top: 10px;
}
.s_vm button{
    padding:2px;
    font-size:10px;
}
.s_vm_details {
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
}
.dialog-custom{
    width: 90% !important;
    max-width:90% !important;
}
.s_vm hr {
  margin: 0;
  padding: 0;
}
.s_vm_head {
  background-color: lightsteelblue;
 
}
.modal-body p {
    word-wrap: break-word;
}
</style>
