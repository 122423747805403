<template>
    <tr>
    <td>{{single_node_data.prox}}</td>
    <td><a :href="'/nodedetails?prox='+single_node_data.prox + '&url=' + single_node_data.url" target="_blank">{{single_node_data.url}}</a></td>
    <td>{{single_node_data.ip}}</td>
    <td><template v-if="single_node_data.online">
      <i class="fas fa-circle green"></i>
    </template>
    <template v-else>
      <i class="fas fa-circle red"></i>
    </template></td>
    <td><template v-if="single_node_data.firewall">
          <span class="green bold"> ON </span>
        </template>
        <template v-if="!single_node_data.firewall">
          <span class="red bold"> OFF </span>
        </template> <a href @click.prevent="restartFirewall(single_node_data.prox)" title="Use if new IPList added to the node">Restart</a><br>
        <a href @click.prevent="reloadFirewall(single_node_data.prox)" title="Use if new IP added to existing IPlist">Reload</a>
        </td>
        <td>{{single_node_data.conf_files}}</td>
    <td>{{single_node_data.office}}</td>
    <td>{{single_node_data.vlan}}</td>
    <td>{{single_node_data.dhcp_router}}</td>
    <td>{{single_node_data.heartbeat.split('+')[0].replace('T',' ')}}</td>
    </tr>
</template>
<script>
import staticData from '../../../data'
import axios from 'axios'
export default {
  name: 'Panel',
  props: ['single_node_data','perms','roles'],
  components:{

  },
  data() {
    return {
     online_count: 0,
     all_count: 0,
     token: localStorage.getItem('token'),
     cgiUrl: staticData.cgiUrl,
     basicUrl: staticData.basicUrl,
     refreshed: '',
     current_user: localStorage.getItem("user")
    };
  },
  methods: {
      countStatus(){
          for(let i = 0; i < this.single_node_data.vms.length; ++i){
    if(this.single_node_data.vms[i].state == 'running')
        this.online_count++
    }
    this.all_count = this.single_node_data.vms.length
      },
    stopMachine(node, machine, name) {
       
       this.refreshed = name

       let config = {
        headers: { Authorization: `Bearer ${this.token}` }
      };

      let data = {
        _node_ip: node,
        _vm_name: name
      };

      axios
        .post(this.basicUrl + "rpc/stopVM", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          
        });
        setTimeout(() => this.$emit('refAll', true), 10000);
          setTimeout(() => this.refreshed = '', 10300);
       
    },
    startMachine(node, machine, name) {
       
       this.refreshed = name

       let config = {
        headers: { Authorization: `Bearer ${this.token}` }
      };

      let data = {
        _node_ip: node,
        _vm_name: name
      };

      axios
        .post(this.basicUrl + "rpc/startVM", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          
        });
        setTimeout(() => this.$emit('refAll', true), 10000);
          setTimeout(() => this.refreshed = '', 10300);
       
    },
    reloadFirewall(px){
      this.$emit('reloadFW', px)
    },
    restartFirewall(px){
      this.$emit('restartFW', px)
      console.log("PX:" + px)
    },
    emitCurrentNode(node){
      this.$emit('currentNode', node)
    },
    emitNodeVm(node,vm){
      this.$emit('actNode', node)
      this.$emit('actVm', vm)
    }
  },
  mounted(){
      this.countStatus()
  }
}
</script>

<style>
.bold{
    font-weight:bold;
}

.new_v_card{
    text-align: left;
    background:white !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}
.new_v_card .card-header{
    padding:5px;
    font-size:12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.new_v_card .card-body{
    font-size:12px;
}
.new_v_card .card-body p{
    font-size:12px;
}
.new_v_card .card-header p button{
    font-size:14px;
}

.fr{
  float:right;
}

</style>