<template>
<div>
    <menu-top></menu-top>
  <div v-if="perms.includes('users-add-user')" id="addUser">
    
    <div class="row">
        <div class="col-md-1">
            <Menu :roles="roles" activeTab="addUser" :perms="perms"></Menu>
          </div>
          <div class="col-md-10 custom-col-wire ml-3">
              <h4 style="text-align:left" class="mt-2">
      Add New User
    </h4>
    <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
    <div class="row mt-3">
      <div class="col-md-5 col-xs-5">
        <input class="form-control" v-model="name" placeholder="Username" type="text" required>
        <input class="form-control mt-2 " v-model="email" placeholder="Email" type="text" required>
        <input class="form-control mt-2" v-model="pass" placeholder="Password" type="password" required>
        <div style="text-align:left;">
        <button class="btn btn-success mt-3" @click="addUser">Add User</button>
        </div>
      </div>
      <div class="col-md-5 col-xs-5">

        <table class="table" style="background-color:white;text-align:left;">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Username</th>
                  <th scope="col">Remove</th>
                </tr>
              </thead>
              <tbody style="text-align:left;">
                  <template v-for="(user,index) in users">
                    
                      <tr :key="index">
                        <td>  {{index + 1}}</td>
                        
                        <td>{{user}}</td>
                        <td><a style="color:red;" href="#" data-toggle="modal" data-target="#removeModal" @click="showPopup(user)">X</a></td>
                      </tr>
                   
                  </template>
                
              </tbody>
            </table>
      </div>
     
      <div class="modal" id="removeModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">WARNING</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p style="color:red;padding:10px;">This will !REMOVE HIS GROUP WITH ALL HIS USERS!
Be sure you reassign his users to at least one other existing group
Otherwise: affected users will be untouched, but orphaned - out of your control, losing contact with the organization</p>
<p>Are you sure you want to remove user: <b>{{currentDelUser}}</b> ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click.prevent="removeUser(currentDelUser)" data-dismiss="modal">Remove</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
        
      </div>
    </div>
  </div>
</div>
    </div>

          </div>
    </div>
  </div>
  <div class="mt-5" v-if="!(perms.includes('users-add-user'))">
       

  </div>
  </div>
</template>

<script>
import axios from "axios";
import staticData from "../data";
import Menu from '../components/NavbarLeft.vue'
import MenuTop from '../components/Navbar.vue'
export default {
  components: {
    Menu,
    MenuTop
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      claims: [],
      name: '',
      pass: '',
      email: '',
      perms:[],
      roles:[],
      currentDelUser: '',
      userRes:[],
      users:[],
      loading: false,
      message: {
        msg: "Server did not return any message"
      },
      show_alert: false,
    };
  },
  methods: {
      getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };

      axios
        .get(
          this.basicUrl + "authClaimsGet?name=eq." + localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.perms.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

          
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });

         axios
        .get(this.basicUrl + "usersGet?groups=cs.{spiros}", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
           this.userRes = res;
           for (let i = 0; i < this.userRes.length; i++) {
            if (!this.users.includes(this.userRes[i].name)) {
              this.users.push(this.userRes[i].name)
            }
          }

        });
       
        
    },
    reloadUsers(){
       let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
       axios
        .get(this.basicUrl + "usersGet?groups=cs.{spiros}", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
           this.userRes = res;
           for (let i = 0; i < this.userRes.length; i++) {
            if (!this.users.includes(this.userRes[i].name)) {
              this.users.push(this.userRes[i].name)
            }
          }

        });
    },
    
    addUser() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
        
        let payload = {
          _name: this.name,
          _pass: this.pass,
          _email: this.email,
          _clearance: 'user'
        };

        axios
          .post(this.basicUrl + "rpc/userAdd", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("ADD:", res);
            
            this.message.msg = res;
              this.alert_type = "success";
              this.show_alert = true;
              setTimeout(() => this.reloadUsers(), 1000);
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
          setTimeout(() => this.reloadUsers(), 1000);
        });
      
     
      
    },
    removeUser(user) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
        
        let payload = {
          _name: user,
        };

        axios
          .post(this.basicUrl + "rpc/userDel", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("DEL:", res);
            
            this.message.msg = res;
              this.alert_type = "success";
              this.show_alert = true;
              setTimeout(() => this.reloadUsers(), 1000);
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
          setTimeout(() => this.reloadUsers(), 1000);
        });
      
     
      
    },
    showPopup(user){
      this.currentDelUser = user
    }
    
  },
  mounted(){
      this.getData();
  }
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .custom-scrollbar {
    width: 70%;
  }
  h4 {
    font-size: 14px;
    text-align: left;
  }
  #addNodeToUser .form-control {
    width: 60%;
    margin-top: 20px;
  }
  #addNodeToUser .btn {
    font-size: 12px;
    padding: 3px;
  }
}
</style>