<template>
<div>
  <div v-if="perms.includes('auth-add-usersclaims') || perms.includes('auth-del-usersclaims')" id="addNodeToUser">
    <h4 style="text-align:left" class="mt-2">
      User Permissions
      <a
        href="#"
        data-toggle="modal"
        data-target="#apiModal"
        class="btn btn-danger pt-1 pb-1 ml-2"
        style="font-size:14px;"
      >API</a>
    </h4> 
     <!-- DEBUG:<br>
      REMOVE:{{oldClaims}}
      ADD:{{newClaims}} -->
    <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >ADD: {{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>ADD: Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
             
            </div>
          </template>
          <template v-if="show_alert2">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >DEL: {{message2.msg}}<span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span>
              <b>DEL: Error {{message2.code}}:</b>
              {{message2.msg}}
              <br />
              <span v-if="message2.details != null && message2.details != ''">
                <b>Details:</b>
                {{message2.details}}
                <br />
              </span>
              <span v-if="message2.hint != null && message2.hint != ''">
                <b>Hint:</b>
                {{message2.hint}}
              </span>
             
            </div>
          </template>

    <i class="fas fa-cog fa-spin fa-5x" v-if="loading"></i>
    <div v-if="!loading" class="row mt-3">
      <div class="col-md-5">
        <select class="form-control" v-model="selectedUser" @change="getData()">
          <option value selected disabled>Select user</option>
          <template v-for="(user,index) in users">
             <template v-if="user != 'swapnil' && user != 'bpo' && user != 'paxton' && user != 'vinay' && user !='bpoaa'">
            <option :key="index" :value="user">{{user}}</option>
            </template>
          </template>
        </select>
      </div>
       <div class="col-md-5">
        <button
          v-if="selectedUser != ''"
          @click="saveClaims"
          class="btn btn-success"
        >Save changes</button>
      </div>
    </div>
    <div v-if="!loading" class="row mt-2">
      <div class="col-md-10">
        <div
          v-if="selectedUser !== ''"
          class="table-wrapper-scroll-y mt-1"
          style="background-color:white;"
        >
        <div class="row pt-2 mb-2">
        <div class="col-md-4">
        <input type="checkbox" v-model="all_selected" @change="changeSelection" />
        Select all
        </div>
        <div class="col-md-8">
        <span style="text-align:right">
              <input v-model="search" type="text" id placeholder="Search" style />
            </span>
            
            </div>
        </div>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th style="position: sticky; top:0; max-width:20px;" scope="col">Allow</th>
                <th style="position: sticky; top:0;" scope="col">Claim</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="selectedUser != ''">
                <template v-for="(perm,index) in filteredList">
                  <tr :key="index">
                    <td style="max-width:20px;">
                      <input
                        :id="'c-'+perm"
                        name="perms"
                        v-model="checkedClaims"
                        :value="perm"
                        type="checkbox"
                        @change="findChanges"
                      />
                    </td>
                    <td>{{perm}}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <api-modal
      :title="'Claims (Permissions) API'"
      :apiTitles="['GET User Claims','Add Claims to User','Delete Claims from user']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/authClaimsUsersGet?name=eq.username','https://rdapi-data.gnuhost.tech/rpc/authClaimsUserAdd','https://rdapi-data.gnuhost.tech/rpc/authClaimsUserDel']"
      :apiPayload="[{},
      {
          _name: 'username',
          _claims: ['wire-add-ip','wire-del-ip']
        },
         {
          _name: 'username',
          _claims: ['wire-add-ip','wire-del-ip']
        }
      ]"
    ></api-modal>
  </div>

  </div>
</template>

<script>
import axios from "axios";
import staticData from "../../data";
import uniq from "lodash/uniq";
import apiModal from "../modals/apiModal.vue";
export default {
  props: ["perms", "users"],
  components: {
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      selectedUser: "",
      userClaimsRes:[],
      userClaims: [],
      checkedClaims: [],
      initClaims: [],
      search: '',
      all_selected: false,
      newClaims: [],
      oldClaims: [],
      show_alert: false,
      show_alert2: false,
      message:{
        msg: ''
      },
      message2:{
        msg: ''
      },
      counter_n: 0,
      counter_o: 0,
      loading: false,
      group_not_exist: false
    };
  },
  methods: {
     filterClaims() {
      this.checkedClaims = [];
      this.initClaims = [];
      this.newClaims = [];
      this.oldClaims = [];
      console.log('PER', this.perms)
      for (let i = 0; i < this.perms.length; i++) {
        if(this.userClaims.length > 0){
        if (
          this.userClaims.includes(this.perms[i])
        ) {
          this.checkedClaims.push(this.perms[i]);
          this.initClaims.push(this.perms[i]);
        }
      }
      }
    },
    getData() {
      this.checkedClaims = [];
      this.initClaims = [];
      this.newClaims = [];
      this.oldClaims = [];
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      axios
        .get(this.basicUrl + "authMyUsersClaims?name=eq." + this.selectedUser, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log('CLAIMS:',res);
          this.userClaimsRes = res;
          this.userClaims = res[0].claims

          this.filterClaims()
          
          if(!(this.perms.includes('auth-add-usersclaims') || this.perms.includes('auth-del-usersclaims'))){
            this.$router.push('/403')
          }
         
        });
    },
   
    findChanges() {
      this.newClaims = [];
      this.oldClaims = [];
     
      for (let i = 0; i < this.checkedClaims.length; i++) {
        if (this.initClaims.indexOf(this.checkedClaims[i]) === -1) {
          this.newClaims.push(this.checkedClaims[i]);
        }
      }
      
      for (let i = 0; i < this.initClaims.length; i++) {
        if (this.checkedClaims.indexOf(this.initClaims[i]) === -1) {
          this.oldClaims.push(this.initClaims[i]);
        }
      }
    },
    changeSelection() {
      if (this.all_selected) {
        for (let i = 0; i < this.filteredList.length; i++) {
          this.checkedClaims.push(this.filteredList[i]);
        }
        this.findChanges()
      } else {
        this.checkedClaims = [];
        this.findChanges()
      }
    },
    saveClaims() {
      this.counter_n = 0;
      this.counter_o = 0;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
        if(this.newClaims.length > 0){

        let payload = {
          _name: this.selectedUser,
          _claims: this.newClaims
        };

        axios
          .post(this.basicUrl + "rpc/authClaimsUserAdd", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("ADD:", res);
            this.counter_n = this.counter_n + 1;
            this.message.msg = res;
            this.alert_type = "success";
            this.show_alert = true;
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
        }
    
      
       if(this.oldClaims.length > 0){
        let payload_d = {
          _name: this.selectedUser,
          _claims: this.oldClaims
        }

        axios
          .post(this.basicUrl + "rpc/authClaimsUserDel", payload_d, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("DEL:", res);
            this.counter_o = this.counter_o + 1;
            this.counter_n = this.counter_n + 1;
            this.message2.msg = res;
            this.alert_type = "success";
            this.show_alert2 = true;
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message2.msg = error.response.data.message;
          this.message2.hint = error.response.data.hint;
          this.message2.details = error.response.data.details;
          this.message2.code = error.response.data.code;
          this.show_alert2 = true;
        });
       }
      setTimeout(() => this.getData(), 3200);
    }
  },
  computed: {
    sortedUsers() {
      return uniq(this.users.map(({ name }) => name));
    },
    filteredList() {
      return this.perms.filter(item => {
        return item.includes(this.search);
      });
    }
  },
  mounted() {
    
  }
};
</script>
<style>
</style>