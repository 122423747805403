var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('menu-top'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1"},[_c('Menu',{attrs:{"roles":_vm.roles,"activeTab":"ssl","perms":_vm.permissions}})],1),(_vm.permissions.includes('ssl-add-cert'))?_c('div',{staticClass:"col-md-5 custom-col-wire ml-3"},[_c('table',{staticClass:"table",staticStyle:{"background-color":"white","text-align":"left"}},[_vm._m(0),_c('tbody',{staticStyle:{"text-align":"left"}},[[_vm._l((_vm.Certs),function(cert,index){return [_c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(cert.domain))]),_c('td',[_vm._v(_vm._s(cert.exp_dt))])])]})]],2)])]):_vm._e()])],_c('api-modal',{attrs:{"title":'Wire API',"apiTitles":['GET IPs','Add IP','Remove IP'],"apiType":['GET','POST','POST'],"apiUrl":['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel'],"apiPayload":[{},
    {
      _ip: '66.66.66.56'
    },
    {
      _ip: '66.66.66.56'
    }
    ]}})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("DOMAIN")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("EXPIRES")])])])}]

export { render, staticRenderFns }