<template>
  <div style="text-align:left;" class="ml-2 mt-1">
   <h1>403 Forbidden</h1>
   <p>You don't have permissions to access this module</p>
   <a href="/">Go back</a>
  </div>
</template>

<script>
export default {
  name: "403",

  data() {
    return {
     
    };
  },
  methods: {
   
    
  },
  computed: {
   
  },
  mounted() {
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
