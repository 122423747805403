<template>
  <div class="login">
    <login-box></login-box>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginBox from '@/components/login/Login.vue'

export default {
  name: 'Login',
  components: {
    LoginBox
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
}
.login{
  padding:30%;
  padding-top:8%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>