<template>
<div>
  <div v-if="perms.includes('prox-add-user') || perms.includes('prox-del-user')" id="addNodeToUser">
    <h4 style="text-align:left" class="mt-2">
      Allow Nodes
      <a
        href="#"
        data-toggle="modal"
        data-target="#apiModal"
        class="btn btn-danger pt-1 pb-1 ml-2"
        style="font-size:14px;"
      >API</a>
    </h4>
    <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >ADD: {{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
          <template v-if="show_alert2">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >DEL: {{message2.msg}}<span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              DEL: {{message2.msg}}
              <br />
              <span v-if="message2.details != null && message2.details != ''">
                <b>Details:</b>
                {{message2.details}}
                <br />
              </span>
              <span v-if="message2.hint != null && message2.hint != ''">
                <b>Hint:</b>
                {{message2.hint}}
              </span>
              
            </div>
          </template>
    <div class="row mt-3">
      <div class="col-md-5 col-xs-5">
        
        <div v-if="selectedUser" style="text-align: left" class="row mt-2">
          <div class="col-md-4">
            <input type="checkbox" v-model="all_selected" @change="changeSelection" />
            Select all
          </div>
          <div class="col-md-4 mb-1">
            <span style="text-align:right">
              <input v-model="search" type="text" id placeholder="Search" style />
            </span>
          </div>
        </div>
        <div class="table-wrapper-scroll-y custom-scrollbar" style="border: 1px solid black">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th v-if="selectedUser != ''" style="position: sticky; top:0;">Allowed</th>
                <th style="position: sticky; top:0;" scope="col">Node</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="node in filteredList">
                <tr :key="node.url">
                  <td v-if="selectedUser != ''">
                    <input
                      @change="updateNodeList"
                      :id="'n-'+node.prox"
                      name="nodes"
                      v-model="checkedNodes"
                      :value="node.prox"
                      type="checkbox"
                    />
                  </td>
                  <td>{{node.url}}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5 col-xs-5">
        <select
          @change="getUserData"
          v-model="selectedUser"
          class="form-control"
         
        >
          <option selected value>Select User</option>
          <template v-for="user in users">
            <option :key="user" :value="user">{{user}}</option>
          </template>
        </select>

        <div
          v-if="selectedUser !== ''"
          class="table-wrapper-scroll-y custom-scrollbar mt-1"
          style="border: 1px solid black;"
        >
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th style="position: sticky; top:0;" scope="col">Node</th>
                <th style="position: sticky; top:0;" scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="node in nodes">
                <template v-if="allowedProxes.includes(node.prox)">
                  <tr :key="node.url">
                    <td>{{node.url}}</td>
                    <td>
                      <a href @click.prevent="removeNode(node.prox)">
                        <i class="red fas fa-minus-circle"></i>
                      </a>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 mt-3" style="text-align:left;">
        <button class="btn btn-success" @click="saveNodes">Save changes</button>
      </div>
    </div>
    <api-modal
      :title="'User Nodes API'"
      :apiTitles="['GET Nodes','Get Users','Get User Allowed Nodes', 'Add allowed Node', 'Delete Allowed Node']"
      :apiType="['GET','GET','POST','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/proxNodesGet','https://rdapi-data.gnuhost.tech/usersGet','https://rdapi-data.gnuhost.tech/rpc/proxNodeAllowGet','https://rdapi-data.gnuhost.tech/rpc/proxNodeAllowAdd','https://rdapi-data.gnuhost.tech/rpc/proxNodeAllowDel']"
      :apiPayload="[{},
      {},
      {_name: 'username'},
      {
          _prox: 666,
          _name: 'username'
        },
        {
          _prox: 666,
          _name: 'username'
        }
      ]"
    ></api-modal>
  </div>
  <div class="mt-5" v-if="!(perms.includes('prox-add-user') || perms.includes('prox-del-user'))">
       

  </div>
  </div>
</template>

<script>
import axios from "axios";
import staticData from "../../data";
import apiModal from "../modals/apiModal.vue";
export default {
  props: ["nodes", "perms", "users"],
  components: {
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      selectedUser: "",
      allowedNodes: [],
      allowedProxes: [],
      checkedNodes: [],
      newNodes: [],
      oldNodes: [],
      counter_n: 0,
      all_selected: false,
      counter_o: 0,
      loading: false,
      search: "",
      message: {
        msg: "Server did not return any message"
      },
      message2: {
        msg: "Server did not return any message"
      },
      show_alert: false,
      show_alert2: false,
    };
  },
  methods: {
    getUserData() {

      this.allowedProxes = [];
      this.checkedNodes = [];
      this.oldNodes = [];
      this.newNodes = [];
      this.all_selected = false;
      this.search = "";
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload = { _name: this.selectedUser };
      axios
        .post(this.basicUrl + "rpc/proxNodeAllowGet", payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.allowedNodes = res;
          if (this.allowedNodes.length > 0) {
            for (let i = 0; i < this.allowedNodes.length; i++) {
              if (
                this.allowedProxes.indexOf(this.allowedNodes[i].prox) === -1
              ) {
                this.allowedProxes.push(this.allowedNodes[i].prox);
                this.checkedNodes.push(this.allowedNodes[i].prox);
              }
              
            }
          } else {
          console.log('')
          }
        });
    },
    updateNodeList() {
      this.newNodes = [];
      this.oldNodes = [];
      for (let i = 0; i < this.checkedNodes.length; i++) {
        if (this.allowedProxes.indexOf(this.checkedNodes[i]) === -1) {
          this.newNodes.push(this.checkedNodes[i]);
        }
      }
      for (let i = 0; i < this.allowedProxes.length; i++) {
        if (this.checkedNodes.indexOf(this.allowedProxes[i]) === -1) {
          this.oldNodes.push(this.allowedProxes[i]);
        }
      }
    },
    changeSelection() {
      if (this.all_selected) {
        for (let i = 0; i < this.filteredList.length; i++) {
          this.checkedNodes.push(this.filteredList[i].prox);
        }
        this.updateNodeList();
      } else {
        this.checkedNodes = [];
        // this.getUserData()
        this.updateNodeList();
      }
    },
    saveNodes() {
      this.counter_n = 0;
      this.counter_o = 0;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      for (let i = 0; i < this.newNodes.length; i++) {
        
        let payload = {
          _prox: this.newNodes[i],
          _name: this.selectedUser
        };

        axios
          .post(this.basicUrl + "rpc/proxNodeAllowAdd", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("ADD:", res);
            this.counter_n = this.counter_n + 1;
            this.message.msg = res;
              this.alert_type = "success";
              this.show_alert = true;
            if (
              this.counter_n + this.counter_o ==
              this.newNodes.length + this.oldNodes.length
            ) {
              
              setTimeout(() => this.getUserData(), 1000);
              
            }
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
          setTimeout(() => this.getUserData(), 1000);
        });
      }
      for (let i = 0; i < this.oldNodes.length; i++) {
        
        let payload = {
          _prox: this.oldNodes[i],
          _name: this.selectedUser
        };

        axios
          .post(this.basicUrl + "rpc/proxNodeAllowDel", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("DEL:", res);
            this.counter_o = this.counter_o + 1;
            this.message2.msg = res;
              this.alert_type = "success";
              this.show_alert2 = true;
            if (
              this.counter_n + this.counter_o ==
              this.newNodes.length + this.oldNodes.length
            ) {
              
              setTimeout(() => this.getUserData(), 1000);
              
            }
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message2.msg = error.response.data.message;
          this.message2.hint = error.response.data.hint;
          this.message2.details = error.response.data.details;
          this.message2.code = error.response.data.code;
          this.show_alert2 = true;
          setTimeout(() => this.getUserData(), 1000);
        });
      }
    },
    removeNode(prox) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.loading = true;
      let payload = {
        _prox: prox,
        _name: this.selectedUser
      };
      axios
        .post(this.basicUrl + "rpc/proxNodeAllowDel", payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
              this.alert_type = "success";
              this.show_alert2 = true;
          setTimeout(() => this.getUserData(), 1000);
          
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message2.msg = error.response.data.message;
          this.message2.hint = error.response.data.hint;
          this.message2.details = error.response.data.details;
          this.message2.code = error.response.data.code;
          this.show_alert2 = true;
          setTimeout(() => this.getUserData(), 1000);
        });
    }
  },
  computed: {
    filteredList() {
      return this.nodes.filter(item => {
        return item.url.includes(this.search);
      });
    }
  }
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .custom-scrollbar {
    width: 70%;
  }
  h4 {
    font-size: 14px;
    text-align: left;
  }
  #addNodeToUser .form-control {
    width: 60%;
    margin-top: 20px;
  }
  #addNodeToUser .btn {
    font-size: 12px;
    padding: 3px;
  }
}
</style>