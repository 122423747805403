<template>
  <div class="panel">
    
    <div class="">
      <div class="row ml-2">
    <template v-for="node in nodes_data">
      <item @refAll="refreshAll" :perms="perms" :roles="roles" @currentNode="currentWhitelist=$event; getData(); currentNodeData=$event;" :single_node_data='node' :key="node.prox"></item>
    </template>
    <whitelist-modal @purge="purgeData()" @loadIpSet="currentList=$event; getData()" @list_changed="getData()" :prox_data="currentWhitelist" :perms="perms" :ip_list="ips" :start_loading="startLoad"></whitelist-modal>
    <vm-details-modal @actNode="currentActNode=$event" @actVm="currentActVm=$event" :node_data="currentNodeData"></vm-details-modal>
    <kvm-modal :current_act_node="currentActNode" :current_act_vm="currentActVm" :key="currentActNode+currentActVm"></kvm-modal>
      </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import staticData from '../../data'
import Item from '@/components/panelViews/classicElements/item.vue'
import whitelistModal from '../modals/whitelistModal'
import vmDetailsModal from '../modals/vmDetailsModal'
import kvmModal from '../modals/kvmModal'
export default {
  name: 'Panel',
  props: ['nodes_data','perms','roles'],
   data() {
    return {
      currentWhitelist: '',
      whitelistData: [],
      basicUrl: staticData.basicUrl,
      ips: [],
      token: localStorage.getItem('token'),
      startLoad: false,
      currentList: "",
      currentNodeData: [],
      currentActNode: null,
      currentActVm: null,
    };
  },
  components: {
     Item,
     whitelistModal,
     vmDetailsModal,
     kvmModal
  },
 methods:{
    getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      
      let data={_ipset: this.currentList}
      if(this.currentList != "" && this.currentList != null){
        this.startLoad = true;
      axios
        .post(this.basicUrl + 'rpc/proxIpsetGet', data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.ips = res.ips;
          this.startLoad = false;
          this.currentList = ""
        })
        .catch(error => {
          this.currentList = ""
          console.log(error)
        });
    }},
    purgeData(){
      this.currentList = ""
      this.ips = []
    },
    refreshAll(){
      this.$emit('refreshAll', true)
    }
  },

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .panel{
   padding:10px;
 }
 .item_card{
   display: inline-block;
   margin-top:5px;
   font-size:12px;
   text-align: left;
 }
</style>
