<template>
  <div>
    <div class="modal fade" id="kvmModal" tabindex="-1" role="dialog" aria-labelledby="kvmModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="kvmModalLabel">KVM</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="!kvmDetailsLoading">
          <p class="red">TO CONNECT USE VNC CLIENT (TigerVNC, Remmina etc.)</p>
        <b class="">Host: </b> {{kvmDetails.url}}<br>
        <b class="">Password: </b> {{kvmDetails.password}}
        <p class="red"> (Password will expire in 2 hours) </p>
        </div>
        <i v-if="kvmDetailsLoading" class="fas fa-cog fa-spin fa-2x"></i>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    
  </div>
</template>

<script>
import axios from 'axios'
import staticData from "../../data";
export default {
  name: "kvmModal",
  props: ["current_act_node", "current_act_vm"],
  data() {
    return {
      cgiUrl: staticData.cgiUrl,
      current_user: localStorage.getItem("user"),
      kvmDetailsLoading: false,
      kvmDetails:{
        url: null,
        password: null
      }
    };
  },
  methods: {
     getKvmDetails() {
      
      this.kvmDetailsLoading = true;
       
      axios
        .get(this.cgiUrl + "vm/kvm/prox" + this.current_act_node + '.gnuhost.eu/' + this.current_act_vm + '/')
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.kvmDetails.url = res.url ;
          this.kvmDetails.password = res.password ;
          this.kvmDetailsLoading = false;
        });
       
    },
    
  },
  mounted() {
    this.getKvmDetails()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s_vm span {
  font-size: 12px;
  padding: 0;
  padding-top: 10px;
}
.s_vm button{
    padding:2px;
    font-size:10px;
}
.s_vm_details {
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
}
.s_vm hr {
  margin: 0;
  padding: 0;
}
.s_vm_head {
  background-color: lightsteelblue;
 
}
</style>
