<template>
<div class="d-flex align-self-stretch">
 <div class="card item_card">
  <div class="card-body d-flex flex-column">
    <h5 class="card-title">
      <a style="color:blue" v-if="perms.includes('prox-fw-get-ip')" href="#" data-toggle="modal" data-target="#whitelistModal" @click.prevent="emitCurrentNode(single_node_data)" class="whitelist_button"> Whitelist</a>
      <a style="color:blue" href="#" data-toggle="modal" data-target="#detailsModal" @click.prevent="emitCurrentNode(single_node_data)" class="whitelist_button ml-1"> Details</a>
      <!-- <span style="float:right">{{single_node_data.location}}</span> -->
      <hr style="margin-top:5px;">
      <p class="mt-2">
       <template v-if="single_node_data.online">
      <i class="fas fa-circle green"></i>
    </template>
    <template v-else>
      <i class="fas fa-circle red"></i>
    </template>
      {{single_node_data.url}} ({{single_node_data.prox}})
      </p>
    </h5>

    <template v-for="vm in single_node_data.vms">
    <p class="card-text" :key="vm.vmid">
      <a v-if="perms.includes('node-vm-destroy')" data-toggle="tooltip" data-placement="top" title="!!! DESTROY VM !!! (UNRECOVERABLE) ! " href="" @click.prevent="destroyVM(single_node_data.ip, vm.name)"><i class="fa fa-times mr-3" style="color:#ffc4c4; font-size:150%; vertical-align:middle;"></i></a>
      <template v-if="refreshed != vm.name">
      <template v-if="vm.state == 'running'">
        <i class="fas fa-circle green"></i>
      </template>
      <template v-if="vm.state == 'stopped'">
        <i class="fas fa-circle red"></i>
      </template>
      </template>
      <template v-if="refreshed == vm.name">
          <i class="fas fa-sync fa-spin"></i>
      </template>
       (<b>{{vm.type}}</b>) {{vm.name}}
       <span class="ml-2">
                  <a data-toggle="tooltip" data-placement="top" title="START VM" v-if="perms.includes('prox-vm-on')" class="act" href @click.prevent="startMachine(single_node_data.ip,vm.name)">
                    <i title="START VM" class="fa fa-play" style="color:forestgreen"></i>
                  </a>
                  <a data-toggle="tooltip" data-placement="top" title="STOP VM" v-if="perms.includes('prox-vm-off')" href class="ml-1" @click.prevent="stopMachine(single_node_data.ip,vm.name)">
                    <i title="STOP VM" class="fa fa-square" style="color:orangered"></i>
                  </a>
                </span>
                
       </p>
    </template>
    
  </div>
  <div style="margin-top:auto; margin-bottom:auto; flex-grow:1; align-self:flex-end;">
  <p class="ml-2">R:{{single_node_data.slots_ram_free}}  D:{{single_node_data.slots_disk_free}} <template v-for="(t,index) in single_node_data.types"><span :key="index">CF_{{t.type}}: {{t.ct}} </span></template></p>
  </div>
</div>

</div>

</template>
<script>
import staticData from '../../../data'
import axios from 'axios'
export default {
  name: 'Panel',
  props: ['single_node_data','perms','roles'],
  data() {
    return {
      current_user: localStorage.getItem("user"),
      a_token: localStorage.getItem("token"),
      cgiUrl: staticData.cgiUrl,
      basicUrl: staticData.basicUrl,
      refreshed: '',
      message: {
        msg: "Server did not return any message"
      },
      show_alert: false,
      alert_type: "",
    };
  },
  methods:{
     stopMachine(node, name) {
       
       this.refreshed = name

       let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };

      let data = {
        _node_ip: node,
        _vm_name: name
      };

      axios
        .post(this.basicUrl + "rpc/stopVM", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          
        });
        setTimeout(() => this.$emit('refAll', true), 10000);
          setTimeout(() => this.refreshed = '', 10300);
       
    },
    startMachine(node, name) {
       
       this.refreshed = name

       let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };

      let data = {
        _node_ip: node,
        _vm_name: name
      };

      axios
        .post(this.basicUrl + "rpc/startVM", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          
        });
        setTimeout(() => this.$emit('refAll', true), 10000);
          setTimeout(() => this.refreshed = '', 10300);
       
    },
    destroyVM(proxIP, vmName) {
      
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _node_ip: proxIP,
        _vm_name: vmName
      };
      if(this.perms.includes('node-vm-destroy')){
      if(confirm("Are you sure you want to delete VM " + vmName )){
        this.refreshed = vmName
      axios
        .post(this.basicUrl + "rpc/destroyVM", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;

        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
        setTimeout(() => this.$emit('refAll', true), 6000);
          setTimeout(() => this.refreshed = '', 6300);
      }
      }
      else{
        alert("42501: insufficient_privilege \nAsk your manager to increase your clearance level or add correct claims")
      }
    },
    emitCurrentNode(node){
      this.$emit('currentNode', node)
    },
  }
}
</script>

<style>
.red{
  color:red;
}
.whitelist_button{
  font-size:14px;
}
.green{
  color:darkgreen;
}
.item_card{
  margin:3px;
  width:100%;
}
.item_card .card-body .card-title{
  font-size:13px;
  font-weight: bold;
}
.item_card .card-body{
  font-size:12px;
  text-align: left;
}
.item_card .card-body p{
  margin-bottom:0;
}
</style>