<template>
  <div class="home">
    <menu-top></menu-top>
    <div class="row">
      <div class="col-md-1">
    <Menu :roles="roles" activeTab="" :perms="permissions"></Menu>
      </div>
      <div class="col-md-10 custom-col" style="">
    <div class="">
      <h4 class="mt-2" style="text-align:left;">Server</h4>
     <table class="table mt-2 table-responsive">
       <thead>
          <th>Prox</th>
          <th>URL</th>
         <th>IP</th>
         <th>ONLINE</th>
         <th>FIREWALL</th>
         <th>VMs</th>
         <th>OFFICE</th>
         <th>VLAN</th>
         <th>ROUTER</th>
         <th>HEARTBEAT</th>
       </thead>
       <tbody>
         <tr>
    <td>{{nodes.prox}}</td>
    <td>{{nodes.url}}</td>
    <td>{{nodes.ip}}</td>
    <td><template v-if="nodes.online">
      <i class="fas fa-circle green"></i>
    </template>
    <template v-else>
      <i class="fas fa-circle red"></i>
    </template></td>
    <td><template v-if="nodes.firewall">
          <span class="green bold"> ON </span>
        </template>
        <template v-if="!nodes.firewall">
          <span class="red bold"> OFF </span>
        </template></td>
        <td>{{nodes.conf_files}}</td>
    <td>{{nodes.office}}</td>
    <td>{{nodes.vlan}}</td>
    <td>{{nodes.dhcp_router}}</td>
    <td>{{nodes.heartbeat}}</td>
    </tr>
       </tbody>
     </table>
     <h4 class="mt-2" style="text-align:left;">VM's</h4>
     <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}} It might take about 30sec to update the status in the panel.<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
     <table  class="table mt-2 table-responsive">
       <thead>
         
         <th>VMID</th>
         <th>NAME</th>
         <th>PORT</th>
         <th>STATE</th>
         <th>RAM</th>
         <th>DISK</th>
         <th>TYPE</th>
         <th>IP</th>
         <th>MAC</th>
         <th>NOTES</th>
         <th>UPDATE_DT</th>
         <th>START</th>
         <th>STOP</th>
       </thead>
       <tbody>
         <template v-for="(vm, index) in nodes.vms">
         <tr :key="index">
          
           <td>{{vm.vmid}}</td>
           <td>{{vm.name}}</td>
           <td>{{vm.port}}</td>
           <td> <template v-if="refreshed != vm.name">
      <template v-if="vm.state == 'running'">
        <i class="fas fa-circle green"></i>
      </template>
      <template v-if="vm.state == 'stopped'">
        <i class="fas fa-circle red"></i>
      </template>
      </template>
      <template v-if="refreshed == vm.name">
          <i class="fas fa-sync fa-spin"></i>
      </template></td>
           <td>{{vm.ram}} MB</td>
           <td>{{vm.disk}} GB</td>
           <td>{{vm.type}}</td>
           <td>{{vm.ip}}</td>
           <td>{{vm.mac}}</td>
           <td>
             <template v-if="vm.notes !== '' && vm.notes !== null && vm.notes !== 'null'">
                              
                               <template v-if="editNote == 'e-'+index">
                                 <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="vm.notes" @keyup.enter="saveNote(vm,index)">
                              </template>
                              <template v-else>
                                <a href="#" @click.prevent="editNote='e-'+index">{{vm.notes}}</a>
                              </template>
                            </template>
                            <template v-else>
                              
                            <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'note-'+index" :value="vm.notes" @keyup.enter="saveNote(vm,index)">
                            </template>

           </td>
           <td>{{vm.update_dt.split('.')[0].replace('T',' ')}}</td>
           <td><a v-if="permissions.includes('prox-vm-on')" class="act" href @click.prevent="startMachine(vm.prox,vm.vmid,vm.name)">
                    <i title="START" class="fa fa-play" style="color:forestgreen"></i>
                  </a></td>
           <td>
             <a v-if="permissions.includes('prox-vm-off')" href class="ml-1" @click.prevent="stopMachine(vm.prox,vm.vmid,vm.name)">
                    <i title="STOP" class="fa fa-square" style="color:orangered"></i>
                  </a>
           </td>
         </tr>
           </template>
       </tbody>
     </table>
     
    </div>
      </div>
  </div>
  <api-modal
      :title="'Servers API'"
      :apiTitles="['GET Prox Nodes']"
      :apiType="['GET']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/proxNodesGet']"
      :apiPayload="[{}
      ]"
    ></api-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Menu from '@/components/NavbarLeft.vue'
import MenuTop from '@/components/Navbar.vue'
import apiModal from "../components/modals/apiModal.vue";
import staticData from '../data'

export default {
  name: 'NodeDetails',
  components: {
     Menu,
     MenuTop,
     apiModal
  },
   data() {
    return {
      apiUrl: staticData.basicUrl,
      cgiUrl: staticData.cgiUrl,
      token: localStorage.getItem('token'),
      currentProx: '',
      nodes: [],
      refreshed: '',
      claims: null,
      a_token: localStorage.getItem("token"),
      permissions: [],
      editNote: '',
      message: {
        msg: "Server did not return any message"
      },
      show_alert: false,
      roles: [],
      loading: false,
      search: ''
    };
  },
  methods:{
    loadData(){
      this.editNote = ''
      this.loading = true
      let config = {
        headers: { Authorization: `Bearer ${this.token}` }
       };
      axios.get( 
        this.apiUrl + 'proxNodesGet?prox=eq.'+this.currentProx,
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          this.nodes = res[0]
          console.log(res[0])
          
        }).catch((error) => {
        if( error.response ){
        console.log(error.response.data.message)
        this.$router.push("/");
    }
        });
    
    axios.get( 
        this.apiUrl + 'authClaimsGet?name=eq.'+localStorage.getItem('user'),
        config
      ).then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res

          for (let i = 0; i < this.claims.length; i++){
            this.permissions.push(this.claims[i].claim)
            if(this.roles.indexOf(this.claims[i].role) === -1){
              this.roles.push(this.claims[i].role)
            }
            
          }

          this.loading = false
        }).catch((error) => {
        if( error.response ){
        console.log(error.response.data.message)
        this.$router.push("/");
    }
});
    },
    saveNote(server,index){
      let currentVal = document.getElementById('note-'+index).value;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _prox: server.prox,
        _vmid: server.vmid,
        _note: currentVal,
        
      }
      console.log('PAY:',payload)
      axios
        .post(this.apiUrl + "rpc/vmNoteSet",payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
              this.alert_type = "success";
              this.show_alert = true;
          this.loadData()
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
    },
    stopMachine(node, machine, name) {
       this.refreshed = name
      axios
        .get(this.cgiUrl + "vm/power/stop/prox" + node + ".gnuhost.tech/" + machine)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          setTimeout(() => this.loadData(), 25000);
          this.refreshed = ''
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
    },
    startMachine(node, machine, name) {
      this.refreshed = name
      axios
        .get(this.cgiUrl + "vm/power/start/prox" + node + ".gnuhost.tech/" + machine)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res)
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          setTimeout(() => this.loadData(), 25000);
          this.refreshed = ''
          
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
    },
    
 
  }, 
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push("/login");
    }
    else{
      this.currentProx = this.$route.query.prox
      window.document.title = this.$route.query.url + ' - Data'
      this.loadData()
    }
   
  }
}
</script>

<style>
.loader{
 margin: auto;
  width: 50%;
  padding: 10px;
}
.panel_nav{
  text-align: left;
  margin-top:10px;
  margin-left:20px;
}
.panel_nav button{
  margin-right:5px;
  margin-left:5px;
  padding:3px;
  font-size:14px;
}
.panel_nav .nav-right{
  text-align:right;
}

.fl-r{
  float:right;
}

.custom-col{
  padding:0; position:relative; left:50px; margin-top:60px;
}


@media only screen and (max-width: 600px) {
  .custom-col{
  padding:0; position:relative; left:80px; margin-top:60px;
}
.fl-r{
  float:none;
  margin-bottom:10px;
}
.fl-r .btn{
  margin:1px;;
  font-size:10px;
  font-weight:bold;
}

}
</style>
