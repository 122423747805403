<template>
<div>
    <menu-top></menu-top>
  <div v-if="perms.includes('nextcloud-add-user') || perms.includes('nextcloud-del-user')" id="addNodeToUser">
    
    <div class="row">
        <div class="col-md-1">
            <Menu :roles="roles" activeTab="userNcs" :perms="perms"></Menu>
          </div>
          <div class="col-md-10 custom-col-wire ml-3">
              <h4 style="text-align:left" class="mt-2">
      Allow NC
    </h4>
    <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >ADD: {{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
          <template v-if="show_alert2">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >DEL: {{message2.msg}}<span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert2=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              DEL: {{message2.msg}}
              <br />
              <span v-if="message2.details != null && message2.details != ''">
                <b>Details:</b>
                {{message2.details}}
                <br />
              </span>
              <span v-if="message2.hint != null && message2.hint != ''">
                <b>Hint:</b>
                {{message2.hint}}
              </span>
              
            </div>
          </template>
    <div class="row mt-3">
      <div class="col-md-5 col-xs-5">
        
        <div v-if="selectedUser" style="text-align: left" class="row mt-2">
          <div class="col-md-4">
            <input type="checkbox" v-model="all_selected" @change="changeSelection" />
            Select all
          </div>
          <div class="col-md-4 mb-1">
            <span style="text-align:right">
              <input v-model="search" type="text" id placeholder="Search" style />
            </span>
          </div>
        </div>
        <div class="table-wrapper-scroll-y custom-scrollbar" style="border: 1px solid black">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th v-if="selectedUser != ''" style="position: sticky; top:0;">Allowed</th>
                <th style="position: sticky; top:0;" scope="col">Nextcloud</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="node in filteredList">
                <tr :key="node.url">
                  <td v-if="selectedUser != ''">
                    <input
                      @change="updateNodeList"
                      :id="'n-'+node.url"
                      name="nodes"
                      v-model="checkedNodes"
                      :value="node.ip"
                      type="checkbox"
                    />
                  </td>
                  <td>{{node.url}}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5 col-xs-5">
        <select
          @change="getUserData"
          v-model="selectedUser"
          class="form-control"
         
        >
          <option selected value>Select User</option>
          <template v-for="user in users">
            <option :key="user" :value="user">{{user}}</option>
          </template>
        </select>

        <div
          v-if="selectedUser !== ''"
          class="table-wrapper-scroll-y custom-scrollbar mt-1"
          style="border: 1px solid black;"
        >
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th style="position: sticky; top:0;" scope="col">Nextcloud</th>
                <th style="position: sticky; top:0;" scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="node in nodes">
                <template v-if="allowedProxes.includes(node.ip)">
                  <tr :key="node.url">
                    <td>{{node.url}}</td>
                    <td>
                      <a href @click.prevent="removeNode(node.ip)">
                        <i class="red fas fa-minus-circle"></i>
                      </a>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 mt-3" style="text-align:left;">
        <button class="btn btn-success" @click="saveNodes">Save changes</button>
      </div>
    </div>

          </div>
    </div>
  </div>
  <div class="mt-5" v-if="!(perms.includes('nextcloud-add-user') || perms.includes('nextcloud-del-user'))">
       

  </div>
  </div>
</template>

<script>
import axios from "axios";
import staticData from "../data";
import Menu from '../components/NavbarLeft.vue'
import MenuTop from '../components/Navbar.vue'
export default {
  components: {
    Menu,
    MenuTop
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      l_user: localStorage.getItem("user"),
      selectedUser: "",
      allowedNodes: [],
      allowedProxes: [],
      checkedNodes: [],
      newNodes: [],
      oldNodes: [],
      nodes: [],
      claims: [],
      perms:[],
      roles:[],
      userRes:[],
      users:[],
      counter_n: 0,
      all_selected: false,
      counter_o: 0,
      loading: false,
      search: "",
      message: {
        msg: "Server did not return any message"
      },
      message2: {
        msg: "Server did not return any message"
      },
      show_alert: false,
      show_alert2: false,
    };
  },
  methods: {
      getData() {

      this.allowedProxes = [];
      this.checkedNodes = [];
      this.oldNodes = [];
      this.newNodes = [];
      this.all_selected = false;
      this.search = "";
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };

      axios
        .get(
          this.basicUrl + "authClaimsGet?name=eq." + localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.perms.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

          
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });

         axios
        .get(this.basicUrl + "usersGet?groups=cs.{"+this.l_user+"}", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
           this.userRes = res;
           for (let i = 0; i < this.userRes.length; i++) {
            if (!this.users.includes(this.userRes[i].name)) {
              this.users.push(this.userRes[i].name)
            }
          }

        });
        axios
        .get(this.basicUrl + "nextcloudServersGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
           this.nodes = res;
        
        });


        
    },
    getUserData() {

      this.allowedProxes = [];
      this.checkedNodes = [];
      this.oldNodes = [];
      this.newNodes = [];
      this.all_selected = false;
      this.search = "";
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };

     

      axios
        .get(this.basicUrl + "nextcloudUsersGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.allowedNodes = res;
          if (this.allowedNodes.length > 0) {
            for (let i = 0; i < this.allowedNodes.length; i++) {
              if (
                this.allowedNodes[i].name==this.selectedUser
              ) {
                this.allowedProxes.push(this.allowedNodes[i].ip);
                this.checkedNodes.push(this.allowedNodes[i].ip);
              }
              
            }
          } else {
          console.log('')
          }
        });
    },
    updateNodeList() {
      this.newNodes = [];
      this.oldNodes = [];
      for (let i = 0; i < this.checkedNodes.length; i++) {
        if (this.allowedProxes.indexOf(this.checkedNodes[i]) === -1) {
          this.newNodes.push(this.checkedNodes[i]);
        }
      }
      for (let i = 0; i < this.allowedProxes.length; i++) {
        if (this.checkedNodes.indexOf(this.allowedProxes[i]) === -1) {
          this.oldNodes.push(this.allowedProxes[i]);
        }
      }
    },
    changeSelection() {
      if (this.all_selected) {
        for (let i = 0; i < this.filteredList.length; i++) {
          this.checkedNodes.push(this.filteredList[i].ip);
        }
        this.updateNodeList();
      } else {
        this.checkedNodes = [];
        // this.getUserData()
        this.updateNodeList();
      }
    },
    saveNodes() {
      this.counter_n = 0;
      this.counter_o = 0;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      for (let i = 0; i < this.newNodes.length; i++) {
        
        let payload = {
          _ip: this.newNodes[i],
          _name: this.selectedUser
        };

        axios
          .post(this.basicUrl + "rpc/nextcloudUserAdd", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("ADD:", res);
            this.counter_n = this.counter_n + 1;
            this.message.msg = res;
              this.alert_type = "success";
              this.show_alert = true;
            if (
              this.counter_n + this.counter_o ==
              this.newNodes.length + this.oldNodes.length
            ) {
              
              setTimeout(() => this.getUserData(), 1000);
              
            }
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
          setTimeout(() => this.getUserData(), 1000);
        });
      }
      for (let i = 0; i < this.oldNodes.length; i++) {
        
        let payload = {
          _ip: this.oldNodes[i],
          _name: this.selectedUser
        };

        axios
          .post(this.basicUrl + "rpc/nextcloudUserDel", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("DEL:", res);
            this.counter_o = this.counter_o + 1;
            this.message2.msg = res;
              this.alert_type = "success";
              this.show_alert2 = true;
            if (
              this.counter_n + this.counter_o ==
              this.newNodes.length + this.oldNodes.length
            ) {
              
              setTimeout(() => this.getUserData(), 1000);
              
            }
          })
          .catch(error => {
          this.alert_type = "danger";
          this.message2.msg = error.response.data.message;
          this.message2.hint = error.response.data.hint;
          this.message2.details = error.response.data.details;
          this.message2.code = error.response.data.code;
          this.show_alert2 = true;
          setTimeout(() => this.getUserData(), 1000);
        });
      }
    },
    removeNode(prox) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.loading = true;
      let payload = {
        _ip: prox,
        _name: this.selectedUser
      };
      axios
        .post(this.basicUrl + "rpc/nextcloudUserDel", payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message2.msg = res;
              this.alert_type = "success";
              this.show_alert2 = true;
          setTimeout(() => this.getUserData(), 1000);
          
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message2.msg = error.response.data.message;
          this.message2.hint = error.response.data.hint;
          this.message2.details = error.response.data.details;
          this.message2.code = error.response.data.code;
          this.show_alert2 = true;
          setTimeout(() => this.getUserData(), 1000);
        });
    }
  },
  computed: {
    filteredList() {
      return this.nodes.filter(item => {
        return item.url.includes(this.search);
      });
    }
  },
  mounted(){
      this.getData();
  }
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .custom-scrollbar {
    width: 70%;
  }
  h4 {
    font-size: 14px;
    text-align: left;
  }
  #addNodeToUser .form-control {
    width: 60%;
    margin-top: 20px;
  }
  #addNodeToUser .btn {
    font-size: 12px;
    padding: 3px;
  }
}
</style>