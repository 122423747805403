<template>
  <div>
    <menu-top></menu-top>
    <div class="row">
      <div class="col-md-1">
    <Menu :roles="roles" activeTab="cerberus" :perms="permissions"></Menu>
      </div>
      <div class="col-md-10 custom-col-cerberus" style="">
        <div v-if="permissions.includes('cerberus-get-domain')" class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h4 style="text-align:left;"><b>Allow or Block CRM IP </b><a href="#" data-toggle="modal" data-target="#apiModal"  class="btn btn-danger pt-1 pb-1" style="font-size:14px;"> API</a></h4>  
    <template>
      <div class="">
        <div v-if="!start_loading" class="row">
          <div class="col-md-4 col-sm-8 col-xs-12 mt-2">
            <select @change="loadLists" class="form-control" v-model="selectedDomain">
              <option value="">Select domain</option>
              <template v-for="domain in domains">
                <option :key="domain" :value="domain">{{domain}}</option>
              </template>
            </select>

          </div>
          <div v-if="selectedDomain != ''" class="col-md-4 col-sm-8 col-xs-12 mt-2 ml-2">
            <select @change="loadIPs" class="form-control" v-model="selectedList">
              <option value="">Select list</option>
              <template v-for="list in lists">
                <option :key="list.name" :value="list">{{list.name}}</option>
              </template>
            </select>

          </div>
        </div>
        <template v-if="show_alert">
              <div v-if="alert_type == 'success'" class="alert alert-success" role="alert">{{message.msg}} <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
              <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
                <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
                <b>Error {{message.code}}:</b> {{message.msg}} <br>
                <span v-if="message.details != null && message.details != ''"><b>Details:</b> {{message.details}} <br></span>
                <span v-if="message.hint != null && message.hint != ''"><b>Hint:</b> {{message.hint}} </span>
                </div>
              </template>
        <div v-if="selectedList != ''" class="whitelisting pb-3 pt-3 mt-2" style="width:70%">
          <div>
                 <div class="">
                    <input type="text" placeholder="255.255.255.255" v-model="current_ip"> <button @click="addIP()">Add IP</button>
                 </div>
                  <div class="mt-2">
                    <input v-model="search" type="text" id="searchInput" placeholder="Search">
                  </div>
                  
              
          </div>
        </div>
                <div class="row">
         <div class="col-md-12">
           <i class="fas fa-cog fa-spin fa-5x" v-if="load_ips"></i>
           <div v-if="!load_ips">
             <div v-if="selectedList != ''" class="table-wrapper-scroll-y custom-scrollbar2" style="background-color:white; width:70%;">
                <table class="table" >
                  <thead>
                    <tr>
                      <th scope="col">IP</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <template v-for="item in filteredList">
                      <template>
                        <tr :key="item">
                          <td>{{item}}</td>
                          <td>
                            <a href @click.prevent="removeIP(item)">
                              <i class="red fas fa-minus-circle"></i>
                            </a>
                          </td>
                        </tr>
                      </template>
                    </template>
                    
                  </tbody>
                </table>
              </div>
           </div>
         </div>
         
        </div>
         <i class="fas fa-cog fa-spin fa-5x" v-if="start_loading"></i>
      </div>
    </template>
        </div>
        <!-- <div class="col-md-6 col-sm-12 col-xs-12 divider" style="">
          <h4 style="text-align:left;"><b>Add To Multiple Domains</b></h4>
          <div class="row">
            <div class="col-md-4">
              <input type="text" class="form-control" placeholder="255.255.255.255" v-model="newIpMulti">
            </div>
            <div class="col-md-4">
              <button class="btn btn-success">Add Ip</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div
                class="t2"
                style="background-color:white; font-size:12px;"
              >
              <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th style="position: sticky; top:0;">#</th>
                      <th style="position: sticky; top:0;" scope="col">IPList</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="list in lists_multi">
                      <tr :key="list">
                        <td>
                          
                          <input :id="'n-'+list" name="lists" v-model="checkedListsMulti" :value="list" type="checkbox">
                          </td>
                        <td>{{list}}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <div style="text-align:left;">
              <input type="checkbox" v-model="all_domains" @change="selectAll"> Select all
              </div>
              <div
                class="table-wrapper-scroll-y custom-scrollbar2"
                style="background-color:white; height:100%"
              >
              <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th style="position: sticky; top:0;">#</th>
                      <th style="position: sticky; top:0;" scope="col">Domain</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="domain in domains">
                      <tr :key="domain">
                        <td>
                          
                          <input :id="'n-'+domain" name="domains" v-model="checkedDomains" :value="domain" type="checkbox">
                          </td>
                        <td>{{domain}}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div> -->
        </div>
      </div>
  </div>
  
   <api-modal
      :title="'Cerberus API'"
      :apiTitles="['GET IpLists','Add IP','Remove IP']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/cerberusIplistGet','https://rdapi-data.gnuhost.tech/cerberusIplistIpAdd','https://rdapi-data.gnuhost.tech/cerberusIplistIpDel']"
      :apiPayload="[{},
      {
         _iplist_id: 1,
         _ips: ['66.66.66.66']
       },
      {
         _iplist_id: 1,
         _ip: '66.66.66.66'
       }
      ]"
    ></api-modal>
  </div>
</template>

<script>
import axios from "axios";
import staticData from "../data";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import apiModal from "../components/modals/apiModal.vue";
export default {
  name: "Admin",
  components: {
    Menu,
    MenuTop,
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      message: {
        msg: "Server did not return any message",
        hint: "",
        details: "",
        code: ""
      },
      start_loading: false,
      claims: null,
      roles: [],
      current_ip: '',
      data: [],
      selectedDomain: "",
      selectedList: "",
      domains: [],
      lists: [],
      search: '',
      alert_type: '',
      show_alert: false,
      ips: [],
      load_ips: false,
      permissions: [],
      checkedDomains: [],
      selectedListMultiple: "",
      lists_multi: [],
      checkedListsMulti:['admin.range', 'common.range', 'static.range'],
      newIpMulti: "",
      all_domains: false,
    };
  },
  methods: {
    getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;

      axios
        .get(
          this.basicUrl + "authClaimsGet?name=eq." + localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }
if(!this.permissions.includes('cerberus-get-domain')){
        this.$router.push("/403")
      }
          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
        

        axios
        .get(
          this.basicUrl + "cerberusIplistGet",
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.data = res;
          console.log(res)

          for (let i = 0; i < this.data.length; i++) {
            if (this.domains.indexOf(this.data[i].domain) === -1) {
              this.domains.push(this.data[i].domain);
            }
          }

          for (let i = 0; i < this.data.length; i++) {
            if (this.lists_multi.indexOf(this.data[i].iplist) === -1) {
              this.lists_multi.push(this.data[i].iplist);
            }
          }

          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });

        
    },
    loadLists(){
      this.selectedList = ""
      this.lists = []
      for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].domain == this.selectedDomain) {
              this.lists.push({'name':this.data[i].iplist, 'id': this.data[i].id});
            }
          }
    },
    loadIPs(){
      this.load_ips = true
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload = {'_domain': this.selectedDomain, '_iplist': this.selectedList.name}

        axios
        .post(
          this.basicUrl + "rpc/cerberusIplistIpGet", payload,
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.ips = res.ips;
          console.log(res.ips)
          this.load_ips = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    removeIP(ip) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
       };
       let data = {
         "_iplist_id": this.selectedList.id,
         "_ip": ip
       }
      axios
        .post(
          this.basicUrl +
            "rpc/cerberusIplistIpDel",
            data,
            config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = 'success';
          this.show_alert = true
          this.load_ips = true;
          this.loadIPs()
          setTimeout(() => this.load_ips = false, 3000);
        }).catch(error => {
            this.alert_type = 'danger';
            this.message.msg = error.response.data.message;
            this.message.hint = error.response.data.hint;
            this.message.details = error.response.data.details;
            this.message.code = error.response.data.code;
            this.show_alert = true
          });
    },
    addIP() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
       };
       let ip_arr = []
       ip_arr.push(this.current_ip)
       let data = {
         "_iplist_id": this.selectedList.id,
         "_ips": ip_arr
       }
        axios
          .post(
            this.basicUrl +
              "rpc/cerberusIplistIpAdd",
              data,
              config
          )
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log(res);
            this.message.msg = res;
            this.alert_type = 'success';
            this.show_alert = true
            
           this.load_ips = true;
          this.loadIPs()
          this.reloadNginx()
          setTimeout(() => this.load_ips = false, 3000);
          })
          .catch(error => {
            this.alert_type = 'danger';
            this.message.msg = error.response.data.message;
            this.message.hint = error.response.data.hint;
            this.message.details = error.response.data.details;
            this.message.code = error.response.data.code;
            this.show_alert = true
          });
          
     
    },
    reloadNginx(){
       let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
       };
       let ip_arr = []
       ip_arr.push(this.current_ip)
       let data = {
         "cmd": "reload"
       }
        axios
          .post(
            this.basicUrl +
              "rpc/cerberusNginxCommand",
              data,
              config
          )
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log('RLD:',res);
            
          })
          .catch(error => {
          console.log('RLD:',error)
          });
    },
    selectAll(){
      if(this.all_domains){
        this.checkedDomains = this.domains
      }
      else{
        this.checkedDomains = []
      }
    }

  },
  computed: {
    filteredList() {
      if(this.ips !== null){
      return this.ips.filter(item => {
        return item.includes(this.search)
      })}
      else{
        return []
      }
    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
  font-size: 12px;
}

.custom-col-cerberus{
  padding:0; position:relative; left:80px; margin-top:60px;
}

.custom-scrollbar .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}

.t2 .table td{
  padding: 0.2rem;
}

.divider{
  border-left:1px solid black;
}

.nav-pills .nav-link{
  border-radius: 0 !important;
}
.flex-column a{
  color:white;
}


@media only screen and (max-width: 600px) {
  .custom-col-cerberus{
  padding:0; position:relative; left:100px; margin-top:60px; font-size:10px;
}
.custom-col-cerberus h4{
  font-size:13px;
  margin-top:20px;
}

.custom-col-cerberus .form-control{
  font-size:12px;
  width:60%;
}

.custom-col-cerberus .btn{
  font-size:12px;
  float:left;
  padding:3px;
  margin-top:10px;
}

.divider{
  border:none;
}

.t2{
  width:70%;
}

}
</style>