<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="iplists" :perms="permissions"></Menu>
        </div>
        <div class="col-md-10 custom-col-sets" style="text-align:left;">
          <h3>IPSets <a href="#" data-toggle="modal" data-target="#apiModal"  class="btn btn-danger pt-1 pb-1" style="font-size:14px;"> API</a></h3>
          <hr>
          <h4>Add new IP Set</h4>
          <template v-if="show_alert1">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert1=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert1=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
             
            </div>
          </template>
          <div class="row">
            <div class="col-md-5">
              <p>Set name:</p>
              <input type="text" v-model="newListName" class="form-control" placeholder="Set name">
              <button class="btn btn-success mt-3" @click="addNewIpSet()">Save</button>
              
            </div>
            <div class="col-md-5">
              <p>CSV (comma separated) list of allowed ips</p>
              <textarea v-model="newIPs" class="form-control ta-custom" placeholder="0.0.0.0, 1.1.1.1, 2.2.2.2"></textarea>
            </div>
          </div>
          
          <div style="border-top: 1px solid black;" class="mt-5 mb-5">
            <div class="row">
            <div class="col-md-6">
            <h4 class="mt-3">Edit / Delete IPSet</h4>
            </div>
            <div class="col-md-2">
              </div>
             <div class="col-md-4" v-if="a_user != 'minos'">
            <h4 class="mt-3">Search IP in sets</h4>
            </div>
            </div>
            <template v-if="show_alert3">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert3=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert3=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
              
            </div>
          </template>
            <div class="row">
            <div class="col-md-3">
              <!-- <select class="form-control" v-model="selectedSetEdit" @change="loadIps()">
                <option value="" selected>Select IPSet</option>
                <template v-for="(set,index) in ipSets">
                  <option v-if="set.ipset != 'gnuhost.iplist'" :key="index" :value="set.ipset">{{set.ipset}}</option>
                </template>
              </select> -->

              <Dropdown
    :options="ipSetsSorted"
    v-model="selectedSetEdit"
    v-on:selected="loadIps"
    :disabled="false"
    name="name"
    :maxItem="50"
    placeholder="Select ipset">
</Dropdown>
              <button class="btn btn-danger mt-1" v-if="selectedSetEdit != ''" @click="deleteIpSet">Delete IPSet</button>
            </div>
            <div class="col-md-5">
              <div class="row" v-if="selectedSetEdit != ''">
                <div class="col-md-6">
                  <input class="form-control" v-model="newIp" type="text" placeholder="255.255.255.255">
                </div>
                <div class="col-md-6">
                  <button class="btn btn-success" @click="addIP()">Add Ip</button>
                </div>
              </div>
              
            </div>
            <div clas="col-md-3" v-if="a_user != 'minos'">
              <input type="text" class="form-control" placeholder="255.255.255.255" v-model="searchIp"><br>
              <button class="btn btn-success" @click.prevent="loadSets()">Search</button>
            </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6" >
                <div
            class=""
            style="background-color:white;" v-if="selectedSetEdit != ''"
          >
            <table class="table">
              <thead style="text-align:center;">
                <tr>
                  <th scope="col">IP</th>
                  <th scope="col">Note</th>
                  <th scope="col">Remove</th>
                </tr>
              </thead>
              <tbody>
                
                <template>
                  <template v-for="(ip,index) in orderedIps">
                    <template>
                      <tr :key="index">
                        <td>{{ip.ip}}</td>
                        <template v-if="ip.note !== '' && ip.note !== ' ' && ip.note !== null && ip.note !== 'null'">
                              
                               <template v-if="editIpNote == 'e-'+index">
                                 <td>
                                 <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'ipnote-'+index" :value="ip.note" @keyup.enter="saveIpNote(ip.ip,index)" @blur="saveIpNote(ip.ip,index)">
                                 </td>
                              </template>
                              <template v-else>
                                <td>
                                <a href="#" @click.prevent="editIpNote='e-'+index">{{ip.note}}</a>
                                </td>
                              </template>
                            </template>
                            <template v-else>
                              <td>
                            <input pattern="[A-Za-z0-9+/=-_+#@]" :key="index" type="text" :id="'ipnote-'+index" :value="ip.note" @keyup.enter="saveIpNote(ip.ip,index)" @blur="saveIpNote(ip.ip,index)">
                            </td>
                            </template>
                        <td>
                          <a href @click.prevent="removeIP(ip)">
                            <i class="red fas fa-minus-circle"></i>
                          </a>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
              </div>
              <div class="col-md-1">
              </div>
              <div class="col-md-4 ml-5" v-if="a_user != 'minos'">
                <table class="table " v-if="searchOpen">
              <thead style="text-align:center;">
                <tr>
                  <th scope="col">IPSets</th>
                </tr>
              </thead>
              <tbody>
                
                <template>
                  <template v-for="(set,index) in sets">
                    <template>
                      <tr :key="index">
                        <td v-if="set != 'gnuhost.iplist'">{{set}}</td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <api-modal
      :title="'IPSets API'"
      :apiTitles="['GET IPSets','Add IPSet','Delete IPSet','Add IP to IPSet', 'Remove IP from IPSet']"
      :apiType="['GET','POST','POST','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/proxIpsetsGet','https://rdapi-data.gnuhost.tech/rpc/proxIpsetAdd','https://rdapi-data.gnuhost.tech/rpc/proxIpsetDel','https://rdapi-data.gnuhost.tech/rpc/proxIpsetIpAdd','https://rdapi-data.gnuhost.tech/rpc/proxIpsetIpDel']"
      :apiPayload="[{},
      {
        _ipset: 'ipsetName'
      },
      {
        _ipset: 'ipsetName'
      },
      {
        _ipset: 'ipsetName',
        _ip: '66.66.66.66'
      },
       {
        _ipset: 'ipsetName',
        _ip: '66.66.66.66'
      }
      ]"
    ></api-modal>
  </div>
</template>

<script>
import Dropdown from 'vue-simple-search-dropdown';
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
import apiModal from "../components/modals/apiModal.vue";
import _ from 'lodash';
export default {
  name: "IpLists",
  components: {
    Menu,
    MenuTop,
    apiModal,
    Dropdown
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ips: [],
      port: "",
      newIp: null,
      newIPs: "",
      newIPsArray:[],
      checkedNodes: [],
      select_all: false,
      current_ip: "",
      validation: false,
      a_token: localStorage.getItem("token"),
      a_user: localStorage.getItem("user"),
      message: {
        msg: "Server did not return any message"
      },
      data: [],
      show_alert1: false,
      show_alert2: false,
      show_alert3: false,
      nodes: [],
      alert_type: "",
      search: "",
      allowedNodes:[],
      newListName: "",
      ipSets: [],
      newNodes: [],
      counter_n: 0,
      loading: false,
      counter_o: 0,
      oldNodes: [],
      selectedProx: "",
      start_loading: false,
      claims: null,
      selectedSet: '',
      selectedSetEdit: '',
      roles: [],
      permissions: [],
      searchIp: '',
      searchOpen: false,
      sets: [],
      editIpNote: '',
      ipSetsSorted:[],
      reloadSetData: ''
    };
  },
  methods: {
    getData() {
      this.editIpNote = ''
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
      if(this.a_user == "sm_tie" || this.a_user == "da_tie"){
        axios
        .get(this.basicUrl + "proxIpsetsGet?ipset=like.*mlyeff*", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.ipSets = res;
        
      this.ipSets.map((set) => {
   this.ipSetsSorted.push({"id":set.ipset, "name":'set: '+set.ipset})
});
      
        });
      }
      else{
        axios
        .get(this.basicUrl + "proxIpsetsGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.ipSets = res;
        this.ipSets.map((set) => {
   this.ipSetsSorted.push({"id":set.ipset, "name":'set: '+set.ipset})
});
      
      
        });
      }
      
        axios
        .get(this.basicUrl + "proxNodesGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.nodes = res;

          
        });
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
   
    loadNodes(){
      this.select_all = false
      this.allowedNodes = []
      this.checkedNodes = []
      for (let i = 0; i < this.nodes.length; i++) {
        if (this.nodes[i].ipsets.includes(this.selectedSet)) {
          this.allowedNodes.push(this.nodes[i].prox);
          this.checkedNodes.push(this.nodes[i].prox);
        }
      }
    },
    
    updateNodeList() {
      this.newNodes = [];
      this.oldNodes = [];
      for (let i = 0; i < this.checkedNodes.length; i++) {
        if (this.allowedNodes.indexOf(this.checkedNodes[i]) === -1) {
          this.newNodes.push(this.checkedNodes[i]);
        }
      }
      for (let i = 0; i < this.allowedNodes.length; i++) {
        if (this.checkedNodes.indexOf(this.allowedNodes[i]) === -1) {
          this.oldNodes.push(this.allowedNodes[i]);
        }
      }
    },
    getNodesData() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
     
        axios
        .get(this.basicUrl + "proxNodesGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.nodes = res;

          this.loadNodes()
        });
      
    },
    selectAllNodes(){
        if (this.select_all) {
        for (let i = 0; i < this.filteredList.length; i++) {
          this.checkedNodes.push(this.filteredList[i].prox);
        }
        this.updateNodeList();
      } else {
        this.checkedNodes = [];
        this.updateNodeList();
      }
      
    },
    loadIps(selection) {
      this.ips = []
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      console.log(selection)
      this.selectedSetEdit=selection.id
      this.reloadSetData=selection.id
      let data = {
        _ipset: selection.id
      };
      if(selection.id){
      axios
        .post(this.basicUrl + "rpc/proxIpsetGet", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.ips = res.jips;
          
        });
     }
      
    },
    reloadIps() {
      this.editIpNote = ''
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      
      let data = {
        _ipset: this.reloadSetData
      };
      axios
        .post(this.basicUrl + "rpc/proxIpsetGet", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.ips = res.jips;
          
        });
     
      
    },
     saveIpNote(ip,index){
      let currentVal = document.getElementById('ipnote-'+index).value;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload={
        _ip: ip,
        _note: currentVal
      }
     console.log(payload)
      axios
        .post(this.basicUrl + "rpc/proxIpsetIpNoteSet",payload, config)
        .then(response => {
          
          return response.data;
        })
        .then(res => {
          console.log(res);
           
          this.reloadIps()
        });
    },
    loadSets() {
      this.sets = []
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      
      axios
        .get(this.basicUrl + "proxIpsetsGetByIP?ip=eq." + this.searchIp, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.sets = res[0].ipsets;
          console.log("aaa",this.sets)
          this.searchOpen = true
          
        });
     
      
    },
    addIpsetServer() {
      this.counter_n = 0;
      this.counter_o = 0;
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      for (let i = 0; i < this.newNodes.length; i++) {
        this.loading = true
        let payload = {
          _prox: this.newNodes[i],
          _ipset: this.selectedSet
        };

        axios
          .post(this.basicUrl + "rpc/proxIpsetAddToNode", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("ADD:", res);
            this.counter_n = this.counter_n + 1;
            if (
              this.counter_n + this.counter_o ==
              this.newNodes.length + this.oldNodes.length
            ) {
              setTimeout(() => this.getNodesData(), 5000);
            }
          });
      }
      for (let i = 0; i < this.oldNodes.length; i++) {
        
        let payload = {
          _prox: this.newNodes[i],
          _ipset: this.selectedSet
        };

        axios
          .post(this.basicUrl + "rpc/proxIpsetDelFromNode", payload, config)
          .then(response => {
            return response.data;
          })
          .then(res => {
            console.log("DEL:", res);
            this.counter_o = this.counter_o + 1;
            if (
              this.counter_n + this.counter_o ==
              this.newNodes.length + this.oldNodes.length
            ) {
              setTimeout(() => this.getUserData(), 5000);
            }
          });
      }
      
    },
    removeIP(ip) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _ipset: this.reloadSetData,
        _ip: ip.ip
      };
      axios
        .post(this.basicUrl + "rpc/proxIpsetIpDel", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert3 = true;
          
          this.getData();
          this.reloadIps();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert3 = true;
        });
    },
    addIP() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _ipset: this.reloadSetData,
        _ips: [this.newIp]
      };
      axios
        .post(this.basicUrl + "rpc/proxIpsetIpAdd", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert3 = true;
          this.getData();
          this.reloadIps();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert3 = true;
        });
    },
    addNewIpSet(){
      this.newIPsArray = this.newIPs.replace(/(\r\n|\n|\r)/gm, "").split(",");
      
      setTimeout(() => (this.applyIps()), 1000);
    },
    deleteIpSet(){
      if(confirm("Are you sure you want to delete Ipset " + this.selectedSetEdit + "?" )){
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _ipset: this.selectedSetEdit
      };
      axios
        .post(this.basicUrl + "rpc/proxIpsetDel", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert3 = true;

         
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert3 = true;
        });
      }
    },
    applyIps(){
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
        let data_part = {
        _ipset: this.newListName + '.iplist',
        _ips: this.newIPsArray
      };
      console.log(data_part)
      this.show_alert1 = false
      axios
        .post(this.basicUrl + "rpc/proxIpsetIpAdd", data_part, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert1 = true;
          this.getData()
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert1 = true;
        });

         
    }
  },
  computed: {
    filteredList() {
      return this.nodes.filter(item => {
        return item.url.includes(this.search);
      })
    },
    orderedIps: function () {
    return _.orderBy(this.ips, ['ip'], ['asc'])
  }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.ta-custom{
  height:150px !important;
  resize:none;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

.custom-col-sets {
  padding: 0;
  position: relative;
  left: 80px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
