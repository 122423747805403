<template>
  <div>
    <div
      class="modal fade"
      id="detailsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="detailsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="detailsModalLabel">{{node_data.url}} {{node_data.location}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <template v-for="vm in node_data.vms">
              <div class="s_vm" :key="vm.name">
                <div class="s_vm_head">
                  <span>{{vm.name}}</span> <button @click.prevent="emitNodeVm(vm.prox, vm.vmid)" data-toggle="modal" data-target="#kvmModal" class="btn btn-danger">KVM</button> <button @click.prevent="emitNodeVm(vm.prox, vm.vmid)" class="btn btn-warning">Note</button>
                  <hr />
                </div>
                <div class="s_vm_details">
                  <div class="row">
                    <div class="col-md-3">
                      <b class="red">Ram:</b>
                      {{vm.ram}}MB
                    </div>
                    <div class="col-md-3">
                      <b class="green">Disk:</b>
                      {{vm.disk}}G
                    </div>
                    <div class="col-md-6">
                      <b>Timestamp:</b>
                      <br />
                      {{vm.timestamp}}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import staticData from "../../data";
export default {
  name: "Details",
  props: ["node_data"],
  data() {
    return {
      cgiUrl: staticData.cgiUrl
    };
  },
  methods: {
    emitNodeVm(node,vm){
      this.$emit('actNode', node)
      this.$emit('actVm', vm)
    }
  },
  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s_vm span {
  font-size: 12px;
  padding: 0;
  padding-top: 10px;
}
.s_vm button{
    padding:2px;
    font-size:10px;
}
.s_vm_details {
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
}
.s_vm hr {
  margin: 0;
  padding: 0;
}
.s_vm_head {
  background-color: lightsteelblue;
 
}
</style>
