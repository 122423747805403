<template>
  <div>
    <div
      class="modal fade"
      id="macModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="macModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog dialog-custom" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="macModalLabel">{{title.name}} MAC Address Whitelist</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body px-2" style="text-align:left">
              <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success mt-2 mb-2"
              role="alert"
            >{{message.msg}}<span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
               <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
             
            </div>
          </template>

              <div class="mb-2 mt-2">
                  <div class="row">
                      <div class="col-md-4">
 
                          <input class="form-control" type="text" v-model="newMAC" placeholder="00:00:00:00:00:00">
                      </div>
                      <div class="col-md-3">
                          <button class="btn btn-success" @click="whitelistMAC()">Whitelist</button>
                      </div>
                  </div>
                  
              </div>
              <hr>
              <div class="mt-2">
              <input v-model="search" type="text" id="searchInput" placeholder="Search" class="mt-2 mb-2">
              <div class="table-wrapper-scroll-y custom-scrollbar">
                <table class="table" style="width:60%">
                  <thead>
                    <tr>
                      <th scope="col">MAC</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item,index) in filteredList">
                      <template v-if="item.mac != ''">
                        <tr :key="index">
                          <td>{{item.mac}}</td>
                          <td><a style="color:red" href="" @click.prevent="removeMAC(item.mac)">Remove</a></td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import staticData from "../../data";
import axios from "axios"
export default {
  name: "WhitelistMAC",
  props: ["data","title"],
  data() {
    return {
      basicUrl: staticData.basicUrl,
      search: '',
      newMAC: '',
      newNote: '',
      show_alert: false,
      message: {
        msg: 'Server did not return any message'
      },
      a_token: localStorage.getItem('token')
    };
  },
  methods: {
      whitelistMAC(){
        let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload = {
          "_hostname": this.title.hostname,
          "_mac": this.newMAC,
          "_common_name": this.title.name
      }
 console.log('te', payload)
      axios
        .post(this.basicUrl + "rpc/vpnOvpnWlmacAdd",payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          this.newMAC = ''
          this.newNote = ''
          this.$emit('refreshData', {"hostname": this.title.hostname, "name": this.title.name})
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
      },
      removeMAC(mac){
        let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let payload = {
          "_hostname": this.title.hostname,
          "_mac": mac,
          "_common_name": this.title.name
      }
 console.log('te', payload)
      axios
        .post(this.basicUrl + "rpc/vpnOvpnWlmacDel",payload, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          this.$emit('refreshData', {"hostname": this.title.hostname, "name": this.title.name})
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
          
        });
      }
  },
   computed: {
    filteredList() {
      return this.data.filter(item => {
        return item.mac.includes(this.search)
      })
    }
  },
  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.s_vm span {
  font-size: 12px;
  padding: 0;
  padding-top: 10px;
}
.s_vm button{
    padding:2px;
    font-size:10px;
}
.s_vm_details {
  text-align: left;
  margin-left: 5px;
  font-size: 12px;
}
.dialog-custom{
    width: 90% !important;
    max-width:90% !important;
}
.s_vm hr {
  margin: 0;
  padding: 0;
}
.s_vm_head {
  background-color: lightsteelblue;
 
}
.modal-body p {
    word-wrap: break-word;
}
</style>
