<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div v-if="permissions.includes('wire-get-ip')" class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="wire" :perms="permissions"></Menu>
        </div>
        <div class="col-md-3 custom-col-wire" style>
          <div class="whitelisting2 pb-3 pt-3">
            <div class="row">
              <div v-if="permissions.includes('wire-add-ip')" class="col-md-6">
                <!-- <vue-ip :ip="ip" :on-change="onIpChange" theme="material"></vue-ip> -->
                <input type="text" placeholder="255.255.255.255" v-model="current_ip" />
                <button @click="addIP()">Add IP</button>
              </div>
              <div v-if="permissions.includes('wire-get-ip')" class="col-md-6">
                <input v-model="search" type="text" id="searchInput" placeholder="Search" />
                <a href="#" data-toggle="modal" data-target="#apiModal"  class="btn btn-danger ml-2" style="padding:5px; font-size:12px;"> API</a>
              </div>
            </div>
          </div>
          <template v-if="show_alert">
            <div
              v-if="alert_type == 'success'"
              class="alert alert-success"
              role="alert"
            >{{message.msg}} <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
            <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
              <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
              <b>Error {{message.code}}:</b>
              {{message.msg}}
              <br />
              <span v-if="message.details != null && message.details != ''">
                <b>Details:</b>
                {{message.details}}
                <br />
              </span>
              <span v-if="message.hint != null && message.hint != ''">
                <b>Hint:</b>
                {{message.hint}}
              </span>
            </div>
          </template>
          <div
            class="table-wrapper-scroll-y"
            style="background-color:white;"
          >
            <table class="table" >
              <thead>
                <tr>
                  <th scope="col">IP</th>
                  <th v-if="permissions.includes('wire-del-ip')" scope="col">Remove</th>
                </tr>
              </thead>
              <tbody style="text-align:left;">
                <i v-if="start_loading" class="fas fa-cog fa-spin fa-2x"></i>
                <template v-if="!start_loading">
                  <template v-for="item in filteredList">
                    <template v-if="permissions.includes('wire-get-ip')">
                      <tr :key="item.ip">
                        <td>{{item.ip}}</td>
                        <td v-if="permissions.includes('wire-del-ip')">
                          <a href @click.prevent="removeIP(item.ip)">
                            <i class="red fas fa-minus-circle"></i>
                          </a>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-7 custom-col-wire ml-3">
            <table class="table table-responsive" style="background-color:white;">
              <thead>
                <tr>
                  <th scope="col">NAME</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">IP</th>
                  <th scope="col">RAM</th>
                  <th scope="col">DISK</th>
                  <th scope="col">DOMAIN</th>
                </tr>
              </thead>
              <tbody style="text-align:left;">
                <template>
                  <template v-for="(server,index) in wireServers">
                    
                      <tr :key="index">
                        <td>{{server.name}}</td>
                        <td><template v-if="server.status == 'running'">
                          <i class="fas fa-circle green"></i>
                          </template>
                          <template v-else>
                              <i class="fas fa-circle red"></i>
                        </template></td>
                        <td>{{server.ip}}</td>
                        <td>{{server.ram}}GB</td>
                        <td>{{server.disk}}GB</td>
                        <td>{{server.subdomain}}</td>
                      </tr>
                   
                  </template>
                </template>
              </tbody>
            </table>
          
        </div>
      </div>
    </template>
    
    <api-modal
      :title="'Wire API'"
      :apiTitles="['GET IPs','Add IP','Remove IP']"
      :apiType="['GET','POST','POST']"
      :apiUrl="['https://rdapi-data.gnuhost.tech/wireIpGet','https://rdapi-data.gnuhost.tech/wireIpAdd','https://rdapi-data.gnuhost.tech/wireIpDel']"
      :apiPayload="[{},
      {
        _ip: '66.66.66.56'
      },
      {
        _ip: '66.66.66.56'
      }
      ]"
    ></api-modal>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
import apiModal from "../components/modals/apiModal.vue";
export default {
  name: "Whitelist",
  components: {
    Menu,
    MenuTop,
    apiModal
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ip: "127.0.0.1",
      port: "",
      current_ip: "",
      validation: false,
      a_token: localStorage.getItem("token"),
      message: {
        msg: "Server did not return any message"
      },
      data: [],
      show_alert: false,
      alert_type: "",
      search: "",
      start_loading: false,
      claims: null,
      roles: [],
      wireServers: [],
      permissions: []
    };
  },
  methods: {
    // onIpChange(ip, port, valid) {
    //   if (valid) {
    //     this.current_ip = ip;
    //     console.log(port);
    //   }
    //   this.validation = valid;
    // },
    getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
      axios
        .get(this.basicUrl + "wireIpGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.data = res;
        });
        axios
        .get(this.basicUrl + "wireServersGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.wireServers = res;
        });
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }
if(!this.permissions.includes('wire-get-ip')){
        this.$router.push("/403")
      }
          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    removeIP(ip) {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _ip: ip
      };
      axios
        .post(this.basicUrl + "rpc/wireIpDel", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          this.getData();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
    },
    addIP() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      let data = {
        _ip: this.current_ip
      };
      axios
        .post(this.basicUrl + "rpc/wireIpAdd", data, config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.message.msg = res;
          this.alert_type = "success";
          this.show_alert = true;
          this.getData();
        })
        .catch(error => {
          this.alert_type = "danger";
          this.message.msg = error.response.data.message;
          this.message.hint = error.response.data.hint;
          this.message.details = error.response.data.details;
          this.message.code = error.response.data.code;
          this.show_alert = true;
        });
    }
  },
  computed: {
    filteredList() {
      return this.data.filter(item => {
        return item.ip.includes(this.search);
      });
    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
