<template>
  <div>
    <template>
      <div class="">
        <menu-top></menu-top>
        <div class="row">
          <!-- <div class="col-md-2 col-sm-4 col-xs-4">
        <div style="border-right:1px solid black; height:100vh; background-color:#212529; margin-top:0;" class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
  <a class="nav-link" id="v-pills-users-tab" data-toggle="pill" href="#v-pills-users" role="tab" aria-controls="v-pills-users" aria-selected="false">Users</a>
  <a @click="selectedOption='addNodeToUser'" class="nav-link" id="v-pills-nodes-tab" data-toggle="pill" href="#v-pills-nodes" role="tab" aria-controls="v-pills-nodes" aria-selected="false">User nodes</a>
  <a class="nav-link" id="v-pills-perms-tab" data-toggle="pill" href="#v-pills-perms" role="tab" aria-controls="v-pills-perms" aria-selected="false">Permissions</a>
  <a @click="selectedOption='userGroups'" class="nav-link" id="v-pills-groups-tab" data-toggle="pill" href="#v-pills-groups" role="tab" aria-controls="v-pills-groups" aria-selected="false">Groups</a>
</div>
          </div> -->
          <div class="col-md-1">
            <Menu :roles="roles" :activeTab="tab" :perms="permissions"></Menu>
          </div>
          <div class="col-md-10" style="padding:0; position:relative; left:100px; margin-top:60px;">
            <div class="" id="">
              
        <add-node-to-user v-if="selectedCard=='nodes'" :users="userList" :perms="permissions" :nodes="nodes"></add-node-to-user>
        <user-groups v-if="selectedCard=='groups'" :users="userRes" :perms="permissions" @reloadUsers="refreshUsers"></user-groups>
        <user-claims v-if="selectedCard=='perms'" :users="userList" :perms="permissions" @reloadUsers="refreshUsers"></user-claims>
</div>
<i class="fas fa-cog fa-spin fa-5x" v-if="start_loading"></i>
          </div>
        </div>
        
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import Menu from '@/components/NavbarLeft.vue'
import MenuTop from '@/components/Navbar.vue'
import staticData from "../data";
import addNodeToUser from "@/components/admin/addNodeToUser.vue"
import userGroups from "@/components/admin/userGroups.vue"
import userClaims from "@/components/admin/userClaims.vue"
export default {
  name: "Admin",
  components: {
    Menu,
    addNodeToUser,
    userGroups,
    userClaims,
    MenuTop
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      a_token: localStorage.getItem("token"),
      l_user: localStorage.getItem("user"),
      message: {
        msg: "Server did not return any message"
      },
      userRes: [],
      userList: [],
      show_alert: false,
      alert_type: "",
      selectedOption: '',
      tab: '',
      nodes: [],
      start_loading: false,
      claims: null,
      roles: [],
      selectedCard: '',
      permissions: []
    };
  },
  methods: {
    // onIpChange(ip, port, valid) {
    //   if (valid) {
    //     this.current_ip = ip;
    //     console.log(port);
    //   }
    //   this.validation = valid;
    // },
    getData() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
      axios
        .get(this.basicUrl + "proxNodesGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.nodes = res;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
      axios
        .get(this.basicUrl + "usersGet?groups=cs.{"+this.l_user+"}", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log('USERS',res);
          this.userRes = res;
           for (let i = 0; i < this.userRes.length; i++) {
            if (!this.userList.includes(this.userRes[i].name)) {
              this.userList.push(this.userRes[i].name)
            }
          }
          console.log(this.userList)
        });

      axios
        .get(
          this.basicUrl + "authClaimsGet?name=eq." + localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
    refreshUsers() {
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };

      axios
        .get(this.basicUrl + "usersGet", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
           this.userRes = res;
           for (let i = 0; i < this.userRes.length; i++) {
            if (!this.userList.includes(this.userRes[i].name)) {
              this.userList.push(this.userRes[i].name)
            }
          }

        });

     
    }
  },
  mounted() {
    this.selectedCard = this.$route.query._card
    this.tab = this.selectedCard
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.nav-pills .nav-link{
  border-radius: 0 !important;
}
.flex-column a{
  color:white;
}
</style>
