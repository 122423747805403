<template>
  <div>
    <template>
      <menu-top></menu-top>
      <div class="row">
        <div class="col-md-1">
          <Menu :roles="roles" activeTab="none" :perms="permissions"></Menu>
        </div>>
        <div class="col-md-10 custom-col-wire ml-3">
          <div style="text-align:left;" class="mb-4">
             <template v-if="show_alert">
              <div v-if="alert_type == 'success'" class="alert alert-success" role="alert">{{message.msg}} <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span></div>
              <div v-if="alert_type == 'danger'" class="alert alert-danger" role="alert">
                <span style="float:right;"><a href @click.prevent="show_alert=false" style="color:black;">X</a></span>
                <b>Error {{message.code}}:</b> {{message.msg}} <br>
                <span v-if="message.details != null && message.details != ''"><b>Details:</b> {{message.details}} <br></span>
                <span v-if="message.hint != null && message.hint != ''"><b>Hint:</b> {{message.hint}} </span>
                </div>
              </template>
          </div>
          <div style="text-align:left;" class="mb-2">
            <h4>Remote Desktop Conection Strings:</h4>
          <input v-model="search" type="text" id="searchInput" placeholder="Search" style="" class="">
          </div>
            <table class="table" style="background-color:white;text-align:left;">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">PX</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">URL</th>
                  <th scope="col">NAME</th>
                  <th scope="col">IP</th>
                </tr>
              </thead>
              <tbody style="text-align:left;">
                <template>
                  <template v-for="(vm,index) in filteredList">
                    
                      <tr :key="index">
                        <td>{{index + 1}}</td>
                        <td>{{vm.prox}}</td>
                        <td>{{vm.type}}</td>
                        <td>{{vm.url}}</td>
                        <td>{{vm.name}}</td>
                        <td>{{vm.ip}}</td>
                      </tr>
                   
                  </template>
                </template>
              </tbody>
            </table>
          
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/NavbarLeft.vue";
import MenuTop from "@/components/Navbar.vue";
import staticData from "../data";
export default {
  name: "VPN",
  components: {
    Menu,
    MenuTop
  },
  data() {
    return {
      basicUrl: staticData.basicUrl,
      ip: "127.0.0.1",
      port: "",
      current_ip: "",
      validation: false,
      a_token: localStorage.getItem("token"),
      message: {
        msg: "Server did not return any message"
      },
      data: [],
      show_alert: false,
      alert_type: "",
      search: "",
      start_loading: false,
      claims: null,
      roles: [],
      editNote: '',
      rds: [],
      newOrder:{
        _count:0,
        _type: "",
        _network:"",
        _notes: null,
},
      currentServer: [],
      permissions: []
    };
  },
  methods: {
    getData() {
      
      let config = {
        headers: { Authorization: `Bearer ${this.a_token}` }
      };
      this.start_loading = true;
  
        axios
        .get(this.basicUrl + "RemoteDesktopList", config)
        .then(response => {
          return response.data;
        })
        .then(res => {
          console.log(res);
          this.rds = res;
        });
      axios
        .get(
          this.basicUrl +
            "authClaimsGet?name=eq." +
            localStorage.getItem("user"),
          config
        )
        .then(response => {
          return response.data;
        })
        .then(res => {
          this.claims = res;

          for (let i = 0; i < this.claims.length; i++) {
            this.permissions.push(this.claims[i].claim);
            if (this.roles.indexOf(this.claims[i].role) === -1) {
              this.roles.push(this.claims[i].role);
            }
          }

      //     if(!this.permissions.includes('vpn-get-server')){
      //   this.$router.push("/403")
      // }

          this.start_loading = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            this.$router.push("/");
          }
        });
    },
 
    
    
  },
  computed: {
    filteredList() {
      return this.rds.filter(item => {
        return item.name.includes(this.search) || item.url.includes(this.search) || item.ip.includes(this.search);
      });
    }
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-scrollbar2 {
  position: relative;
  height: 90%;
  overflow: auto;
  font-size: 12px;
}

.custom-scrollbar2 .table td,
.table th {
  padding: 4px;
}
.table-wrapper-scroll-y {
  display: block;
}

.whitelisting2 {
  font-size: 12px;
  background-color: darkslategrey;
}
.modal-body {
  padding: 0;
}
.custom-col-wire {
  padding: 0;
  position: relative;
  left: 50px;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .custom-col-wire {
    padding: 0;
    position: relative;
    left: 100px;
    margin-top: 60px;
  }
  .custom-scrollbar2 {
    position: relative;
    height: 90%;
    overflow: auto;
    font-size: 10px;
    width: 70%;
  }

  .whitelisting2 {
    font-size: 10px;
    background-color: darkslategrey;
    width: 70%;
  }
}
</style>
