<template>
 <div class="container text-center">
   <form class="form-signin">
     
      <h1 class="h3 mb-3 font-weight-normal">Sign In</h1>
      <label for="inputUsername" class="sr-only">Username</label>
      <input type="text" id="inputUsername" class="form-control" v-model="login_data.name" placeholder="Username" required autofocus>
      <label for="inputPassword" class="sr-only">Password</label>
      <input type="password" id="inputPassword" v-model="login_data.pass" class="mt-1 form-control" placeholder="Password" required>

      <button class="btn btn-lg btn-primary btn-block mt-2" type="submit" @click.prevent="userLogin">Sign in</button>
     
      

    </form>
    <a href="#" data-toggle="modal" data-target="#apiModal"  class="btn btn-danger mt-3"> API</a>
    <api-modal :title="'Login API'" :apiTitles="['Login']" :apiType="['POST']" :apiUrl="['https://rdapi-auth.gnuhost.tech/rpc/login']" :apiPayload="[{
        name: 'username',
        pass: 'password'
      }]"></api-modal>
 </div>
</template>

<script>
import axios from "axios";
import apiModal from "../modals/apiModal.vue"
export default {
  name: 'Login',
  components:{
    apiModal
  },
  data() {
    return {
      authUrl: "https://rdapi-auth.gnuhost.tech/rpc/login",
      login_data:{
        name: null,
        pass: null
      },
    };
  },
  methods: {
    userLogin(){
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      axios
        .post(this.authUrl, this.login_data)
        .then(response => {
          return response.data;
        })
        .then(res => {
          localStorage.setItem('token', res[0].token)
          localStorage.setItem('user', this.login_data.name)
          console.log(res[0].token)
          this.$router.push("/");
        }).catch((error) => {
    if( error.response ){
      console.log(error.response.data)
        let mess = error.response.data.code + ':' + error.response.data.message
        alert(mess)
    }
});
    },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
